import React, { useState, useEffect } from "react";
import { Remove, Plus } from "../../../components/icons";
import { Select } from "antd";
import { get, isEmpty, filter } from "lodash";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";

import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SeasonEpisodeImage from "../../../images/file.jpg";
import ButtonTextImage from "../../../images/file_720.jpg";
import CoverPhoto from "../../../images/cover_photo.png";
import ShowPhoto from "../../../images/show_photo.png";
import ShowName from "../../../images/Show_Name.jpeg";
import ShowDesc from "../../../images/Show_Description.jpeg";
import CreditImage from "../../../images/Credits.jpeg";
import { Info } from "../../../components/icons";
import { Popover } from "antd";

const { Option } = Select;
let children = [];

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const imageOne = (
  <>
    <div className="img-tooltip">
      <img src={SeasonEpisodeImage} className="image-popover" />
    </div>
  </>
);

const imageTwo = (
  <>
    <div className="img-tooltip">
      <img src={ButtonTextImage} className="image-popover" />
    </div>
  </>
);

const showPhoto = (
  <>
    <div className="img-tooltip">
      <img src={ShowPhoto} className="image-popover" />
    </div>
  </>
);

const coverPhoto = (
  <>
    <div className="img-tooltip">
      <img src={CoverPhoto} className="image-popover" />
    </div>
  </>
);
const showNameImg = (
  <>
    <div className="img-tooltip">
      <img src={ShowName} className="image-popover" />
    </div>
  </>
);
const showDescImg = (
  <>
    <div className="img-tooltip">
      <img src={ShowDesc} className="image-popover" />
    </div>
  </>
);
const showCreditImg = (
  <>
    <div className="img-tooltip">
      <img src={CreditImage} className="image-popover" />
    </div>
  </>
);

export default function GeneralComponent({ data, props }) {
  const [selectedStatus, setSelectedStatus] = useState(
    get(props, "getShowDetailsByIdData", [])[0]?.appDisplayStatus === "active"
      ? "show"
      : "hide"
  );
  const [showTag, setshowTag] = useState([]);
  const [coverPreviewArr, setCoverPreviewArr] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [coverLoader, setCoverLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [selectSeasons, setSelectedSeasons] = useState("");
  const [episode, setEpisode] = useState([]);
  const [isEpisode, setIsEpisode] = useState("episode");
  const [initialState, setInitialState] = useState({});
  const [selectDefaultSeason, setSelectDefaultSeason] = useState("");
  const [selectedSeasonNumber, setSelectedSeasonNumber] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [buttonClick, setButtonClick] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  //   let buttonClick = [false, false, false, false, false];

  console.log("buttonClick", buttonClick);

  const publishedData = filter(episode, function (v) {
    return v.episodeObjectImported?.attributes?.published === true;
  });

  useEffect(() => {
    let initvalues = {
      _id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      title: get(props, "getShowDetailsByIdData", [])[0]?.title,
      descriptionPlain: get(props, "getShowDetailsByIdData", [])[0]
        ?.descriptionPlain,
      defaultSeason: get(props, "getShowDetailsByIdData", [])[0]?.defaultSeason,
      selectedSeasonNumber: get(props, "getShowDetailsByIdData", [])[0]
        ?.selectedSeasonNumber,
      cover_image: get(props, "getShowDetailsByIdData", [])[0]?.cover_image,
      showImage: get(props, "getShowDetailsByIdData", [])[0]?.showImage,
      tags: get(props, "getShowDetailsByIdData", [])[0]?.tags,
      appDisplayStatus: get(props, "getShowDetailsByIdData", [])[0]
        ?.appDisplayStatus,
      showCredits: get(props, "getShowDetailsByIdData", [])[0]?.showCredits,
      adFreeFeedUrl: get(props, "getShowDetailsByIdData", [])[0]?.adFreeFeedUrl,
      adFeedUrl: get(props, "getShowDetailsByIdData", [])[0]?.adFeedUrl,
      showDetailButtonText: get(props, "getShowDetailsByIdData", [])[0]
        ?.showDetailButtonText,
      featuredSeasonId:
        get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true"
          ? get(props, "getShowDetailsByIdData", [])[0]?.featuredSeasonId[0]
              ?._id
          : "",
      featuredEpisodeId: get(props, "getShowDetailsByIdData", [])[0]
        ?.featuredEpisodeId[0]?._id,
      categories:
        get(props, "getShowDetailsByIdData", [])[0]?.categories?.length > 0
          ? get(props, "getShowDetailsByIdData", [])[0]?.categories
          : [],
    };
    if (get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true") {
      setSelectedSeasons(
        get(props, "getShowDetailsByIdData", [])[0]?.featuredSeasonId[0]?._id
      );
    }
    setSelectedEpisode(
      get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]?._id
    );

    // children = get(props, "getShowDetailsByIdData", [])[0]?.tags
    setshowTag(get(props, "getShowDetailsByIdData", [])[0]?.tags);
    if (get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true") {
      setSeasons(get(props, "getShowDetailsByIdData", [])[0]?.seasons);
    } else {
      setSeasons([]);
      setEpisode(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
      setSelectedEpisode(
        get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]?._id
      );
    }
    setSelectedStatus(
      get(props, "getShowDetailsByIdData", [])[0]?.appDisplayStatus === "active"
        ? "show"
        : "hide"
    );
    // setEpisode(get(props,'getShowDetailsByIdData',[])[0]?.seasons[0])
    setInitialState(initvalues);
    setisLoading(false);
    if (get(props, "updateShowDetailsPhase", "") === "success") {
      props.resetLoginPhase();
      props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      openNotificationWithIcon("success", "show details updated successfully.");
    }
    if (
      seasons.length > 0 &&
      get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true" &&
      get(props, "getShowDetailsByIdData", [])[0]?.featuredSeasonId[0]?._id
    ) {
      if (episode === undefined) {
        setEpisode([]);
      }
      // console.log(episode,'seasons?.length',seasons?.length)
      let epidata = seasons?.find(
        (d) =>
          d._id ===
          get(props, "getShowDetailsByIdData", [])[0]?.featuredSeasonId[0]?._id
      );
      setEpisode([...epidata?.episodesIds]);
    } else {
      if (seasons.length === 0) {
        if (get(props, "getShowDetailsByIdData", [])[0]?.seasons) {
          let epidata = get(
            props,
            "getShowDetailsByIdData",
            []
          )[0]?.seasons?.find(
            (d) =>
              d._id ===
              get(props, "getShowDetailsByIdData", [])[0]?.featuredSeasonId[0]
                ?._id
          );
          // console.log(epidata?.episodesIds,'epidata?.episodesIds')
          setEpisode(epidata?.episodesIds);
        } else {
          setEpisode(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
        }
      }
    }
  }, [data, props]);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  useEffect(() => {}, [
    coverPreviewArr,
    imageError,
    showLoader,
    showPreviewArr,
    seasons,
  ]);

  const activeStatus = [
    { key: "show", value: "Show" },
    { key: "hide", value: "Hide" },
  ];

  const handleChangeCategories = (index, data) => {
    const categories = initialState["categories"];
    var ind = data.children ? data.children : index;

    // console.log("od", ind);
    if (!categories.includes(data.value)) {
      categories.push(data.value);
      initialState["categories"] = categories;
      setInitialState({ ...initialState });
    }
  };

  const handleRemoveCategories = (e, data) => {
    const categories = initialState["categories"];
    const index = categories.indexOf(e);

    if (index > -1) {
      categories.splice(index, 1);
      initialState["categories"] = categories;
      setInitialState({ ...initialState });
    }
  };

  const changeSeasons = (value) => {
    initialState["featuredSeasonId"] = value;
    setInitialState(initialState);
    setSelectedSeasons(value);
    //console.log(setSelectedSeasons(value),"setSelectedSeason>>>>>>")
    let epidata = seasons?.find((d) => d._id === value);
    setEpisode(epidata?.episodesIds);
  };

  const defaultSeasons = (values) => {
    let seasonData = seasons.find((d) => d._id.toString() === values);

    initialState["defaultSeason"] = values;
    initialState["selectedSeasonNumber"] = seasonData.season_number;
    setInitialState(initialState);
    setSelectDefaultSeason(values);
    //setSelectedSeasonNumber(seasonData.season_number);
    //console.log("seasonData",seasonData);
  };

  const episodeStatus = [
    { key: "episode", value: "Episode" },
    { key: "trailer", value: "Trailer/Teaser" },
  ];

  const bytesToSize = (bytes) => bytes / (2048 * 2048);

  const addImages = async (acceptedFiles, keys) => {
    if (acceptedFiles.length > 0) {
      if (keys === "cover_image") {
        setCoverLoader(true);
      } else {
        setShowLoader(true);
      }
      setImageError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            // if (this.bytesToSize(data.size) <= 10) {
            acceptedFiles[key].preview = URL.createObjectURL(data);
            const fileSize = (bytesToSize(acceptedFiles[key].size) * 10) / 100;
            const newFle = await imageCompression(acceptedFiles[key], {
              maxSizeMB: fileSize,
            });
            const file = new File([newFle], "image");
            if (keys == "cover_image") {
              let form_data = new FormData();
              form_data.append("cover_image", newFle);
              let { value } = await props.uploadImages(form_data);
              initialState["cover_image"] = value?.url;
              setInitialState(initialState);
              setCoverPreviewArr(data);
              setCoverLoader(false);
              // initialState['cover_image'] = data
              // setInitialState(initialState)
            } else {
              let form_data = new FormData();
              form_data.append("showImage", newFle);
              let { value } = await props.uploadImages(form_data);
              initialState["showImage"] = value?.url;
              setInitialState(initialState);
              setShowPreviewArr(data);
              setShowLoader(false);
              // initialState['showImage'] = data
              // setInitialState(initialState)
            }
          } else {
            setImageError("Please use jpeg or png format.");
            setCoverLoader(false);
            setShowLoader(false);
          }
        });
      }
    } else {
      setImageError("Please use jpeg or png format.");
      setCoverLoader(false);
      setShowLoader(false);
    }
  };

  const handleTextChange = (name, value) => {
    initialState[name] = value;
    setInitialState({ ...initialState });
  };

  const onSelectedStatus = (key, value) => {
    if (key === "appDisplayStatus" && (value === "hide" || value === "show")) {
      setSelectedStatus(value);
      initialState["appDisplayStatus"] =
        value === "hide" ? "inactive" : "active";
    } else {
      setIsEpisode(value);
    }
    setInitialState(initialState);
  };

  const handleChange = async (val, key) => {
    let finalVal = [];
    let ll = val.map((v) => {
      if (v !== " " && v !== "" && !finalVal.includes(v)) {
        finalVal.push(v);
      }
    });
    await Promise.all(ll);
    initialState["tags"] = finalVal;
    setInitialState(initialState);
    setshowTag(finalVal);
    children.push(<Option key={val}>{val}</Option>);
  };

  const updateShowDetails = () => {
    // setButtonClick(true);
    // buttonClick = [true, true, true, true, true];
    setButtonClick(Array(6).fill(true));
    if (get(initialState, "title", "") === "") {
    } else if (get(initialState, "descriptionPlain", "") === "") {
    } else if (get(initialState, "showDetailButtonText", "") === "") {
    } else if (
      (isEpisode === "episode" && selectSeasons === undefined) ||
      selectSeasons === "0"
    ) {
    } else if (selectedEpisode === undefined || selectedEpisode === "0") {
    } else if (
      !get(initialState, "coverPreviewArr.preview", "") &&
      !get(initialState, "cover_image", "")
    ) {
    } else if (
      !get(initialState, "showPreviewArr.preview", "") &&
      !get(initialState, "showImage", "")
    ) {
    } else {
      if (get(initialState, "featuredSeasonId", "") === "") {
        initialState.featuredSeasonId = null;
      }

      props.updateShowDetails(initialState);
    }
  };

  return (
    <>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          {isLoading ||
            (!initialState?.title && (
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 30, color: "#000" }}
                    spin
                  />
                }
                className="loader__full"
              />
            ))}
          <div className="custom__wrap">
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label className="label-primary mb-0">Active in the app?</label>
                <div className="ms-auto">
                  <div className="custom__radio__group">
                    {activeStatus.map((d, i) => {
                      return (
                        <>
                          <input
                            className="custom__radio__group--button"
                            id={d.key}
                            type="radio"
                            checked={selectedStatus === d.key}
                            onClick={() =>
                              onSelectedStatus("appDisplayStatus", d.key)
                            }
                          />
                          <label
                            className="custom__radio__group--label"
                            htmlFor={d.key}
                          >
                            {d.value}
                          </label>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              {/* <label className="label-primary">
              Show Name <span className="required">*</span>
            </label> */}

              <label className="label-primary image-space">
                Show Name <span className="required">*</span>
                <Popover
                  overlayClassName="info-overlay"
                  content={showNameImg}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <input
                type="text"
                name="title"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                value={get(initialState, "title", "")}
                className="form-control material-textfield-input"
              />
              {!get(initialState, "title", "") && (
                <div className="invalid-feedback">Please enter title.</div>
              )}
            </div>

            <div className="form-group">
              {/* <label className="label-primary">
              Show Description
              <span className="required">*</span>
            </label> */}
              <label className="label-primary image-space">
                Show Description
                <span className="required">*</span>
                <Popover
                  overlayClassName="info-overlay"
                  content={showDescImg}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>

              <div className="form-group material-textfield">
                <textarea
                  type="text"
                  className="form-control material-textfield-input"
                  name="descriptionPlain"
                  required
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      const updatedButtonClick = [...buttonClick];
                      updatedButtonClick[0] = true;
                      setButtonClick(updatedButtonClick);
                      handleTextChange(e.target.name, e.target.value);
                    } else {
                      const updatedButtonClick = [...buttonClick];
                      updatedButtonClick[0] = false;
                      setButtonClick(updatedButtonClick);
                      handleTextChange(e.target.name, e.target.value);
                    }
                  }}
                  value={get(initialState, "descriptionPlain", "")}
                  cols={20}
                  rows={10}
                  wrap="description"
                ></textarea>
                {!get(initialState, "descriptionPlain", "") &&
                buttonClick[0] ? (
                  <div className="invalid-feedback">
                    Please enter description.
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group">
              {/* <label className="label-primary">Show Credits</label> */}
              <label className="label-primary image-space">
                Show Credits
                <Popover
                  overlayClassName="info-overlay"
                  content={showCreditImg}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>

              <div className="form-group material-textfield">
                <textarea
                  type="text"
                  className="form-control material-textfield-input"
                  name="showCredits"
                  required
                  onChange={(e) =>
                    handleTextChange(e.target.name, e.target.value)
                  }
                  value={get(initialState, "showCredits", "")}
                  cols={10}
                  rows={5}
                  wrap="showCredits"
                ></textarea>
                {/* {!get(initialState, "showCredits", "") && (
                <div className="invalid-feedback">
                  Please enter Show Credits.
                </div>
              )} */}
              </div>
            </div>

            <div className="form-group">
              {/* <label className="label-primary">
              Cover Photo (recommended dimensions){" "}
            </label> */}
              <label className="label-primary image-space">
                Cover Photo (recommended dimensions)
                <Popover
                  overlayClassName="info-overlay"
                  content={coverPhoto}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) => {
                  const updatedButtonClick = [...buttonClick];
                  updatedButtonClick[1] = false;
                  setButtonClick(updatedButtonClick);
                  //   buttonClick[1] = false;
                  addImages(acceptedFiles, "cover_image");
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {coverLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {get(coverPreviewArr, "preview", "") === "" &&
                get(initialState, "cover_image", "") === "" &&
                buttonClick[1] && (
                  <div className="invalid-feedback">
                    Please upload cover image.
                  </div>
                )}
              {(get(initialState, "cover_image", "") ||
                !isEmpty(coverPreviewArr)) && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(coverPreviewArr)
                          //   ? coverPreviewArr.preview
                          get(initialState, "cover_image", "")
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            const updatedButtonClick = [...buttonClick];
                            updatedButtonClick[1] = true;
                            setButtonClick(updatedButtonClick);

                            setCoverPreviewArr({});
                            initialState["cover_image"] = "";
                            setInitialState(initialState);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              {/* <label className="label-primary">
              Show Photo (recommended dimensions)
            </label> */}
              <label className="label-primary image-space">
                Show Photo (recommended dimensions)
                <Popover
                  overlayClassName="info-overlay"
                  content={showPhoto}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) => {
                  const updatedButtonClick = [...buttonClick];
                  updatedButtonClick[2] = false;
                  setButtonClick(updatedButtonClick);
                  //   buttonClick[2] = false;
                  addImages(acceptedFiles, "showImage");
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {showLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {!get(showPreviewArr, "preview", "") &&
                !get(initialState, "showImage", "") &&
                buttonClick[2] && (
                  <div className="invalid-feedback">
                    Please upload show image.
                  </div>
                )}
              {(get(initialState, "showImage", "") ||
                !isEmpty(showPreviewArr)) && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(showPreviewArr)
                          //   ? showPreviewArr.preview
                          get(initialState, "showImage", "")
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            const updatedButtonClick = [...buttonClick];
                            updatedButtonClick[2] = true;
                            setButtonClick(updatedButtonClick);

                            setShowPreviewArr({});
                            initialState["showImage"] = "";
                            setInitialState(initialState);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isEpisode === "episode" &&
              get(props, "getShowDetailsByIdData", [])[0]?.isEpisode ===
                "true" && (
                <div className="form-group">
                  <label className="label-primary">
                    Select Default Season{" "}
                  </label>
                  <select
                    onChange={(e) => defaultSeasons(e.target.value)}
                    value={get(initialState, "defaultSeason", [])}
                    //value={selectDefaultSeason}

                    className="form-control custom-select material-textfield-input"
                    required
                  >
                    <option value="0">Select</option>
                    {seasons?.length > 0 &&
                      seasons?.map((s, i) => {
                        return (
                          <option key={i} value={s._id}>
                            {s.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

            {selectedStatus === "show" && (
              <div className="form-group">
                <label className="label-primary">Select Categories </label>
                <Select
                  value={get(initialState, "categories", [])}
                  mode="tags"
                  style={{ width: "100%" }}
                  className="multiple__tags__textfield"
                  // placeholder="Enter Phone, if sending to multiple people use a comma to separate the emails"
                  // onSearch={this.onSearchMail.bind(this)}
                  onSelect={handleChangeCategories}
                  onDeselect={handleRemoveCategories}
                  filterOption={(input, option) =>
                    option.props.value
                      ?.toLowerCase()
                      ?.indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {get(props, "categoryData", []).length > 0 &&
                    get(props, "categoryData", []).map((d, key) => {
                      if (d.status !== "inactive") {
                        return (
                          <>
                            {
                              <Option key={key} value={get(d, "_id", "")}>
                                {get(d, "name", "")}
                              </Option>
                            }
                          </>
                        );
                      }
                    })}
                </Select>
              </div>
            )}
            <div className="form-group">
              <div className="custom__radio__group">
                {episodeStatus.map((d, i) => {
                  return (
                    <>
                      <input
                        className="custom__radio__group--button"
                        id={d.key}
                        type="radio"
                        checked={isEpisode === d.key}
                        onClick={() => onSelectedStatus("isEpisode", d.key)}
                      />
                      <label
                        className="custom__radio__group--label"
                        htmlFor={d.key}
                      >
                        {d.value}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>

            {isEpisode === "episode" &&
              get(props, "getShowDetailsByIdData", [])[0]?.isEpisode ===
                "true" && (
                <div className="form-group">
                  <label className="label-primary">
                    Show Detail: Select Featured Season{" "}
                  </label>
                  <select
                    onChange={(e) => {
                      const updatedButtonClick = [...buttonClick];
                      updatedButtonClick[5] = false;
                      setButtonClick(updatedButtonClick);
                      changeSeasons(e.target.value);
                    }}
                    value={selectSeasons}
                    className="form-control custom-select material-textfield-input"
                    required
                  >
                    <option value="0">Select</option>
                    {seasons?.length > 0 &&
                      seasons?.map((s, i) => {
                        return (
                          <option key={i} value={s._id}>
                            {s.title}
                          </option>
                        );
                      })}
                  </select>
                  {((isEpisode === "episode" &&
                    selectSeasons === undefined &&
                    buttonClick[5]) ||
                    (selectSeasons === "0" && buttonClick[5])) && (
                    <div className="invalid-feedback">
                      please assign feature season
                    </div>
                  )}
                </div>
              )}

            <div className="form-group">
              {/* <label className="label-primary">
              Show Detail: Select Featured Season Episode
            </label> */}
              <label className="label-primary image-space">
                Show Detail: Select Featured Season Episode
                <Popover
                  overlayClassName="info-overlay"
                  content={imageOne}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <select
                onChange={(e) => {
                  //   {
                  //     // console.log("value>>>>>>>>>>", e.target.value);
                  //   }
                  initialState["featuredEpisodeId"] = e.target.value;
                  //   {
                  //     // console.log("initialvalue>>>>>>>>>>", initialState);
                  //   }
                  setInitialState(initialState);
                  //   {
                  //     // console.log("setvalue>>>>>>", e.target.value);
                  //   }
                  setSelectedEpisode(e.target.value);
                  const updatedButtonClick = [...buttonClick];
                  updatedButtonClick[3] = false;
                  setButtonClick(updatedButtonClick);
                  //   buttonClick[3] = false;
                }}
                value={selectedEpisode}
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                {episode?.length > 0 &&
                  publishedData.map((e, i) => {
                    if (
                      // e.episodeNumber &&
                      e.episodeObjectImported.attributes.published === true
                    ) {
                      return (
                        <option key={i} value={e._id}>
                          {e.title}
                        </option>
                      );
                    }
                  })}
              </select>
              {(selectedEpisode === undefined || selectedEpisode === "0") &&
                buttonClick[3] && (
                  <div className="invalid-feedback">
                    please assign feature episode
                  </div>
                )}
            </div>
            <div className="form-group">
              {/* <label className="label-primary">
              Show Detail: Featured Podcast Button Text{" "}
            </label> */}
              <label className="label-primary image-space">
                Show Detail: Featured Podcast Button Text
                <Popover
                  overlayClassName="info-overlay"
                  content={imageTwo}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <input
                type="text"
                value={get(initialState, "showDetailButtonText", "")}
                name="showDetailButtonText"
                onChange={(e) => {
                  //   buttonClick[4] = false;
                  if (e.target.value.length === 0) {
                    const updatedButtonClick = [...buttonClick];
                    updatedButtonClick[4] = true;
                    setButtonClick(updatedButtonClick);
                    handleTextChange(e.target.name, e.target.value);
                  } else {
                    const updatedButtonClick = [...buttonClick];
                    updatedButtonClick[4] = false;
                    setButtonClick(updatedButtonClick);
                    handleTextChange(e.target.name, e.target.value);
                  }
                }}
                className="form-control material-textfield-input"
              />
              {get(initialState, "showDetailButtonText", "") === "" &&
                buttonClick[4] && (
                  <div className="invalid-feedback">
                    Please enter button text
                  </div>
                )}
            </div>

            {/*<div className="form-group">
            <label className="label-primary">Show Tags</label>
            <input
              type="text"
              className="form-control material-textfield-input"
            />
          </div>*/}
            <div className="form-group">
              <label className="label-primary">Show Tags</label>
              <Select
                value={showTag}
                // className="form-control material-textfield-input"
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Show Tags"
                onChange={handleChange}
              >
                {children}
              </Select>
            </div>

            <div className="form-group">
              <label className="label-primary">Art 19 Ad Free Feed URL</label>
              <input
                name="adFreeFeedUrl"
                value={get(initialState, "adFreeFeedUrl", "")}
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                className="form-control custom-select material-textfield-input"
              />
            </div>

            <div className="form-group">
              <label className="label-primary">Art 19 Ad Feed URL</label>
              <input
                name="adFeedUrl"
                value={get(initialState, "adFeedUrl", "")}
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                className="form-control custom-select material-textfield-input"
              />
            </div>

            <button
              onClick={() => updateShowDetails()}
              type="submit"
              className="btn btn-lg btn__green w-100"
            >
              Save & Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
