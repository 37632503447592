import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Select } from "antd";

import ActionMoreComponent from "../../../components/common/ActionMore/component";
import {
  Edit,
  Eye,
  Setting,
  Plus,
  Remove,
  DragIcon,
} from "../../../components/icons";
import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spin, notification, Checkbox } from "antd";
import { get, isEmpty, filter } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Popover } from "antd";
import PodcastBtnImg from "../../../images/GZM_Morning_show.png";
import { Info } from "../../../components/icons";
import SeasonCoverPhoto from "../../../images/season_cover_photo.jpeg";
import SeasonPhoto from "../../../images/Season_Image.jpg";
import moment from "moment";

const { Option } = Select;
let children = [];

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const popoverImage = (
  <>
    <div className="img-tooltip">
      <img src={PodcastBtnImg} className="image-popover" />
    </div>
  </>
);

const seasonCoverImg = (
  <>
    <div className="img-tooltip">
      <img src={SeasonCoverPhoto} className="image-popover" />
    </div>
  </>
);
const seasonImg = (
  <>
    <div className="img-tooltip">
      <img src={SeasonPhoto} className="image-popover" />
    </div>
  </>
);

export default function EpisodesSeasonsComponent({ data, props }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalOpen, setModalIsOpen] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [featuredEpisode, setFeaturedEpisode] = useState([]);
  const [nullEpisodes, setNullEpisodes] = useState([]);
  const [selectSeasons, setSelectedSeasons] = useState("");
  const [selectSeasonsData, setSelectedSeasonsData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEpisode, setIsEpisode] = useState("episode");
  const [selectedEpisode, setSelectedEpisode] = useState("");
  const [showDetailButtonText, setShowDetailButtonText] = useState("");
  const [openModalData, setOpenModalData] = useState({});
  const [hideModal, setHideModal] = useState(false);
  const [coverLoader, setCoverLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [episodeLoading, setEpisodeLoading] = useState(false);
  const [coverPreviewArr, setCoverPreviewArr] = useState({});
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [openImageModal, setImageModal] = useState(false);
  const [openSImageModal, setSImageModal] = useState(false);
  const [imageUrl, setImage] = useState("");
  const [seasonImageUrl, setSeasonImageUrl] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [newUpdateEpisode, setNewUpdateStatus] = useState(false);
  const [episodeDetailsUpdate, setEpisodeDetailsUpdate] = useState(false);
  // const [epiDetailsUpdate, setEpiDetailsUpdate] = useState(false);
  const [orderingChange, setOrderingChange] = useState(false);
  const [seasonIndex, setSeasonIndex] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
  const [openHideModal, setOpenHideModal] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [allChecked, setAllChecked] = useState(true);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);
  const [selectedEpi, setSelectedEpi] = useState();
  const [allCheckedPremium, setAllCheckedPremium] = useState(false);
  const [selectedEpiPremium, setSelectedEpiPremium] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [featuredEpisodeId, setFeaturedEpisodeId] = useState("");
  const [onOffSaveButton, setOnOffSaveButton] = useState(
    selectSeasons === "0" ? true : false
  );
  const [seasonState, setSeasonState] = useState("");
  const [featuredSeasonId, setFeaturedSeasonId] = useState("");
  const [featuredTitle, setFeaturedTitle] = useState("");
  const [isSeason, setIsSeason] = useState(false);
  const [statusAlertModal, setStatusAlertModal] = useState(false);
  const [epiFeature, setEpiFeature] = useState("");

  const [itunes, setItunes] = useState("");
  const [selectSeasonCategories, setSelectSeasonCategoriess] = useState([]);
  const location = useLocation();
  const params = useParams();

  // const handleChange = (event, openModalData ) => {
  //   console.log("event",event)
  //   if (event.target.checked) {

  //     !openModalData?.episodeObjectImported?.attributes?.published === false ? false : true
  //      console.log('Checkbox is checked');
  //   } else {
  //     console.log('Checkbox is NOT checked');
  //   }
  //    setOpenModalData()
  //    setIsSubscribed(current => !current);
  //    {!e?.episodeObjectImported?.attributes?.published === false ? false : true}

  // };

  // {console.log('openModalData',openModalData?.episodeName)}

  const assignSeason = (values) => {
    validationCheck(values, "assignSeason");
    // let seasonData = seasons.find((d) => d._id.toString() === values);
    // console.log("seasonData",seasonData)
    // setSeasonState(values);
    openModalData["assignSeason"] = values;
    openModalData.orderIndex = 0;
    setOpenModalData({ ...openModalData });
  };

  const handleChange = async (id) => {
    let test = episodes;
    const data = test?.find((t) => t?._id?.toString() === id?.toString());

    let newStatus = data?.episodeObjectImported?.attributes?.published;

    let newEpisode = [];
    let s = test?.map((t) => {
      if (!t?.episodeObjectImported?.attributes?.published) {
        newEpisode.push(t);
      }
    });
    await Promise.all(s);
    setSelectedEpisodes(newEpisode);
    const { value } = await props.episodeShowHide({ id: id });
  };

  const onCheck = async (item, key = "single") => {
    let ids = [];
    if (key === "single") {
      const found = selectedEpisodes.findIndex(
        (el) => el._id?.toString() === item._id?.toString()
      );
      if (found === -1) {
        setSelectedEpisodes([...selectedEpisodes, item]);
        if (selectedEpisodes?.length + 1 === episodes?.length) {
          setAllChecked(false);
        }
      } else {
        selectedEpisodes.splice(found, 1);
        setAllChecked(true);
        setSelectedEpisodes([...selectedEpisodes]);
      }

      let prm = selectedEpisodes?.map((s) => {
        ids.push(s?._id);
      });
      await Promise.all(prm);
      let test = episodes;
      const data = test?.find(
        (t) => t?._id?.toString() === item._id?.toString()
      );

      let newStatus = !data?.episodeObjectImported?.attributes?.published;
      let newEpisode = [];
      let s = test?.map((t) => {
        if (t) {
          if (t?._id?.toString() === item._id?.toString()) {
            t.episodeObjectImported.attributes.published = newStatus;
          }
          newEpisode.push(t);
        }
      });
      await Promise.all(s);
      setEpisodes([...newEpisode]);
      const { value } = await props.episodeShowHide({ ids: [item?._id] });

      if (value?.status === true) {
        props.getShowDetailsById({
          id: get(props, "getShowDetailsByIdData", [])[0]?._id,
        });
      }
    } else {
      const markData = [];
      if (!allChecked) {
        setAllChecked(true);
        setSelectedEpisodes([]);
        let prm = episodes?.map((s) => {
          if (
            !s?.episodeObjectImported?.attributes?.published &&
            s?.episodeNumber
          ) {
            ids.push(s?._id);
          }
        });
        await Promise.all(prm);
        const { value } = await props.episodeShowHide({ ids: ids });
        if (value.status) {
          window.location.reload();
        }
      } else {
        setSelectedEpisodes([...episodes]);
        setAllChecked(false);

        let prm = episodes?.map((s) => {
          if (
            s?.episodeObjectImported?.attributes?.published &&
            s?.episodeNumber
          ) {
            ids.push(s?._id);
          }
        });

        await Promise.all(prm);

        const { value } = await props.episodeShowHide({ ids: ids });
        if (value?.status) {
          setLoadingData(true);
          window.location.reload();
        }
      }
    }
    setOpenHideModal(false);
  };

  const onCheckPremium = async (item, key = "single") => {
    let ids = [];
    if (key === "single") {
      const found = selectedEpiPremium.findIndex(
        (el) => el._id?.toString() === item._id?.toString()
      );
      if (found === -1) {
        setSelectedEpiPremium([...selectedEpiPremium, item]);
        if (selectedEpiPremium?.length + 1 === episodes?.length) {
          setAllCheckedPremium(true);
        }
      } else {
        selectedEpiPremium.splice(found, 1);
        setAllCheckedPremium(false);
        setSelectedEpiPremium([...selectedEpiPremium]);
      }

      let prm = selectedEpiPremium?.map((s) => {
        ids.push(s?._id);
      });
      await Promise.all(prm);
      let test = episodes;
      const data = test?.find(
        (t) => t?._id?.toString() === item._id?.toString()
      );
      let newStatus = !data?.episodePremium;
      let newEpisode = [];
      let s = test?.map((t) => {
        if (t) {
          if (t?._id?.toString() === item._id?.toString()) {
            t.episodePremium = newStatus;
          }
          newEpisode.push(t);
        }
      });
      await Promise.all(s);
      setEpisodes([...newEpisode]);
      const { value } = await props.premiumEpisode({ ids: [item?._id] });

      if (value?.status === true) {
        props.getShowDetailsById({
          id: get(props, "getShowDetailsByIdData", [])[0]?._id,
        });
      }
    } else {
      const markData = [];
      if (allCheckedPremium) {
        setAllCheckedPremium(false);
        setSelectedEpiPremium([]);
        let prm = episodes?.map((s) => {
          if (s?.episodePremium && s?.episodeNumber) {
            ids.push(s?._id);
          }
        });
        await Promise.all(prm);
        const { value } = await props.premiumEpisode({ ids: ids });
        if (value.status) {
          window.location.reload();
        }
      } else {
        setSelectedEpiPremium([...episodes]);
        setAllCheckedPremium(true);

        let prm = episodes?.map((s) => {
          if (!s?.episodePremium && s?.episodeNumber) {
            ids.push(s?._id);
          }
        });

        await Promise.all(prm);

        const { value } = await props.premiumEpisode({ ids: ids });
        if (value?.status) {
          setLoadingData(true);
          window.location.reload();
        }
      }
    }
  };

  const premiumForSeasonZero = async (id) => {
    let test = nullEpisodes;
    const data = test?.find((t) => t?._id?.toString() === id?.toString());

    let newStatus = !data?.episodePremium;

    let newEpisode = [];
    let s = test?.map((t) => {
      if (t) {
        if (t?._id?.toString() === id?.toString()) {
          t.episodePremium = newStatus;
        }
        newEpisode.push(t);
      }
    });
    await Promise.all(s);
    setNullEpisodes(newEpisode);
    const { value } = await props.premiumEpisode({ ids: [id] });
  };

  const handleChangeForSeasonZero = async (id) => {
    let test = nullEpisodes;
    const data = test?.find((t) => t?._id?.toString() === id?.toString());

    let newStatus = !data?.episodeObjectImported?.attributes?.published;

    let newEpisode = [];
    let s = test?.map((t) => {
      if (t) {
        if (t?._id?.toString() === id?.toString()) {
          t.episodeObjectImported.attributes.published = newStatus;
        }
        newEpisode.push(t);
      }
    });
    await Promise.all(s);
    setNullEpisodes(newEpisode);
    const { value } = await props.episodeShowHide({ ids: [id] });
  };

  const onDragEnd = async (result) => {
    const newItems = Array.from(episodes);
    const [removed] = newItems.splice(result?.source?.index, 1);
    newItems.splice(result?.destination?.index, 0, removed);
    setEpisodes(newItems);
    setOrderingChange(true);
    const { value } = await props.updateEpisodeSeasonOrderIndex({
      episodeData: newItems,
    });
    if (value?.data) {
      props.getShowDetailsById({
        id: params.id,
      });
    }
  };

  const onDragEndForNullEpisode = async (result) => {
    const newItems = Array.from(nullEpisodes);
    const [removed] = newItems.splice(result?.source?.index, 1);
    newItems.splice(result?.destination?.index, 0, removed);
    setNullEpisodes(newItems);
    setOrderingChange(true);

    const { value } = await props.updateEpisodeSeasonOrderIndex({
      episodeData: newItems,
    });
    if (value?.data) {
      props.getShowDetailsById({
        id: params.id,
      });
    }
  };

  useEffect(() => {
    let initvalues = {
      seasonCategories:
        get(props, "getShowDetailsByIdData", [])[0]?.seasons?.[0]
          ?.seasonCategories?.length > 0
          ? get(props, "getShowDetailsByIdData", [])[0]?.seasons?.[0]
              ?.seasonCategories
          : [],
    };
    // setEpisode(get(props,'getShowDetailsByIdData',[])[0]?.seasons[0])
    setInitialState({ ...initvalues });
    // console.log(" setInitialState(initvalues)", initvalues);
  }, [props]);

  useEffect(() => {
    if (get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true") {
      setIsSeason(seasons?.length > 0 ? true : false);

      setSeasons(
        seasons?.length > 0
          ? seasons
          : get(props, "getShowDetailsByIdData", [])[0]?.seasons
      );

      setFeaturedEpisodeId(
        featuredEpisodeId
          ? featuredEpisodeId
          : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]
              ?.featuredEpisodeId
      );

      // setItunes(
      //   itunes
      //     ? itunes
      //     : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]?.episodesIds[0]?.episodeObjectImported?.attributes?.itunes_type
      // );

      setFeaturedSeasonId(
        featuredSeasonId
          ? featuredSeasonId
          : get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]
              ?.seasonId
      );

      setSelectSeasonCategoriess([
        ...get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]
          ?.seasonCategories,
      ]);

      setFeaturedTitle(
        featuredTitle
          ? featuredTitle
          : get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]
              ?.title
      );

      // setFeaturedEpisodeId(
      //   seasons?.length > 0
      //     ? featuredEpisodeId
      //     : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]?.featuredEpisodeId
      // );

      setFeaturedEpisode(
        featuredEpisode?.length > 0
          ? featuredEpisode
          : get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]
      );

      setEpisodes(
        episodes?.length > 0
          ? episodes
          : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]?.episodesIds
      );

      const callApi = async () => {
        let nullEpisodesData = [];
        let prm = get(props, "getShowDetailsByIdData", [])[0]?.seasons?.map(
          (s) => {
            s.episodesIds.map((e) => {
              // if(!e.episodeNumber){
              if (
                !e.episodeNumber &&
                nullEpisodesData.findIndex(
                  (ep) => ep._id?.toString() === e._id?.toString()
                ) === -1
              ) {
                nullEpisodesData.push(e);
              }
            });
          }
        );
        await Promise.all(prm);
        setNullEpisodes(nullEpisodesData);
      };
      callApi();
      // if (episodes?.length === 0) {
      //   setEpisodes(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
      // }
      if (newUpdateEpisode) {
        setEpisodes(
          get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]?.episodesIds
        );
        // if (episodes?.length === 0) {
        //   setEpisodes(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
        // }
        setNewUpdateStatus(false);
      }
      if (selectSeasons !== "") {
        setSelectedSeasons(selectSeasons);
        let epidata = seasons.find((d) => d._id === selectSeasons);
        setSelectedSeasonsData(epidata);
        setImage(epidata?.image);
        setSeasonImageUrl(epidata?.season_cover_photo);
        setShowDetailButtonText(epidata?.showDetailButtonText);
        if (!orderingChange) {
          setEpisodes(epidata?.episodesIds);
        }
      } else {
        setSelectedSeasons("0");
        // setSelectedSeasons(   *Select Season 0*
        //   selectSeasons
        //     ? selectSeasons
        //     : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]._id
        // );

        setSelectedSeasonsData(
          !isEmpty(selectSeasonsData)
            ? selectSeasonsData
            : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]
        );
      }
      if (imageUrl === "") {
        setImage(get(props, "getShowDetailsByIdData", [])[0]?.seasons[0].image);
      }

      if (seasonImageUrl === "") {
        setSeasonImageUrl(
          get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]
            .season_cover_photo
        );
      }

      setShowDetailButtonText(
        showDetailButtonText
          ? showDetailButtonText
          : get(props, "getShowDetailsByIdData", [])[0]?.seasons[0]
              ?.showDetailButtonText
      );

      setLoadingData(false);
    } else {
      if (selectSeasons !== "") {
        setSelectedSeasons(selectSeasons);
        let epidata = seasons.find((d) => d._id === selectSeasons);
        setSelectedSeasonsData(epidata);
        setImage(epidata?.image);
        setSeasonImageUrl(epidata?.season_cover_photo);
        setShowDetailButtonText(epidata?.showDetailButtonText);
        if (!orderingChange) {
          setEpisodes(epidata?.episodesIds);
        }
      } else {
        setSeasons([]);
        setSelectedSeasons("0");
      }
      const callApi = async () => {
        let nullEpisodesData = [];
        let prm = get(props, "getShowDetailsByIdData", [])[0]?.episodesIds?.map(
          (e) => {
            // if (e.episodeNumber) {
            nullEpisodesData?.push(e);
            // }
          }
        );

        // await Promise.all(prm);
        setNullEpisodes(nullEpisodesData);
      };
      callApi();
      setEpisodes(
        episodes?.length > 0
          ? episodes
          : get(props, "getShowDetailsByIdData", [])[0]?.episodesIds
      );
      if (newUpdateEpisode) {
        setEpisodes(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
        if (episodes?.length === 0) {
          setEpisodes(get(props, "getShowDetailsByIdData", [])[0]?.episodesIds);
        }
        setNewUpdateStatus(false);
      }
      setEpiFeature(
        get(
          props,
          "getShowDetailsByIdData",
          []
        )[0]?.featuredEpisodeId[0]?._id?.toString()
      );
      setShowDetailButtonText(
        showDetailButtonText
          ? showDetailButtonText
          : get(props, "getShowDetailsByIdData", [])[0]?.episodesIds[0]
              ?.showDetailButtonText
      );
      setLoadingData(false);
    }
    setSelectedEpisode(
      get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]?._id
    );
    if (get(props, "updateShowDetailsPhase", "") === "success") {
      props.resetLoginPhase();
      props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      let time = setInterval(async () => {
        setLoading(false);
        setOpenModal(false);
        openNotificationWithIcon(
          "success",
          "show details updated successfully."
        );
        clearInterval(time);
      }, 3000);
    }
    if (episodeDetailsUpdate) {
      if (get(props, "getShowDetailsByIdData", [])[0]?.isEpisode === "true") {
        let ddseasons = get(props, "getShowDetailsByIdData", [])[0].seasons;
        let epidata = ddseasons?.find((d) => d._id === selectSeasons);
        let ep = epidata?.episodesIds;
        if (!orderingChange) {
          setEpisodes(ep);
        }
      }
    }
  }, [props, episodes]);
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    const save = (e) => {
      let charCode = String.fromCharCode(e.which).toLowerCase();
      if ((e.ctrlKey || e.metaKey) && charCode === "s") {
        updateEpiDetails();
        closeModal();
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", save);
    return () => window.removeEventListener("keydown", save);
  }, [openModalData]);
  useEffect(() => {}, [
    coverPreviewArr,
    imageError,
    showLoader,
    showPreviewArr,
    seasons,
    episodes,
    imageUrl,
    seasonImageUrl,
    openModalData,
  ]);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const opModal = async (key) => {
    let episodeData = get(
      props,
      "getShowDetailsByIdData",
      []
    )[0]?.seasons?.find((d) => d?._id?.toString() === key);
    setOpenModal(true);
    await setFeaturedEpisodeId(episodeData?.featuredEpisodeId);
    await setShowDetailButtonText(episodeData?.showDetailButtonText);
  };

  const openModalHide = (data) => {
    setOpenModalData(data);
    setHideModal(true);
  };

  const handleTextChangeEpisode = (name, value) => {
    // let episodeData = get(props, "getShowDetailsByIdData", [])[0]?.episodes?.find(
    //   (d) => d?._id?.toString() === episodeId)
    if (name === "itunes_type") {
      //     if (value === "full") {
      //       setItunes("teaser");
      //     } else {
      //       setItunes(value);
      //     }
      validationCheck(value, name);
      setItunes(value);
      // openModalData.episodeObjectImported.attributes.itunes_type = value;
      openModalData.episodeName = value;
      if (openModalData?.seasonId) {
        openModalData.episodeNumber = 5000;
        openModalData.orderIndex = 0;
      }
    } else {
      validationCheck(value, name);
      openModalData[name] = value;
    }
    setOpenModalData({ ...openModalData });
  };

  const updateEpisodeDetails = async () => {
    let appDisplayStatus =
      openModalData.appDisplayStatus === "active" ? "inactive" : "active";
    let { value } = await props.updateEpisodeDetails({
      _id: openModalData._id,
      appDisplayStatus: appDisplayStatus,
      // assignSeason: openModalData.seasonId
    });

    if (get(value, "success", "") === true) {
      props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      let time = setInterval(() => {
        openNotificationWithIcon(
          "success",
          "Update episode details successfully."
        );
        setHideModal(false);
        clearInterval(time);
      }, 3000);
    }
  };

  const updateEpiDetails = async () => {
    console.log("enry update EPisode Details");
    if (get(openModalData, "title", "") === "") {
      console.log("enry update EPisode Details  1");
    } else if (get(openModalData, "publishedAt", "") === "") {
      console.log("enry update EPisode Details  2");
    } else if (get(openModalData, "descriptionPlain", "") === "") {
      console.log("enry update EPisode Details  3");
    } else if (get(openModalData, "shortDescription", "") === "") {
      console.log("enry update EPisode Details  4");
    } else if (selectSeasons === "0" && seasons.length > 0) {
      console.log("enry update EPisode Details  5");
      openModalData.episodeObjectImported.attributes.itunes_type = itunes;
      // if(openModalData?.episodeObjectImported?.attributes?.itunes_type){
      //   if (get(openModalData, "assignSeason", "") === "") {
      //   if (get(openModalData, "episodeName", "") === "") {
      console.log("itunes", itunes);
      if (itunes === "" || !itunes) {
        console.log("enry update EPisode Details inner 1");
      }
      //    else if (get(openModalData, "seasonName", "") === "") {
      else {
        console.log("entry data");
        setEpisodeLoading(true);
        setEpisodeDetailsUpdate(true);
        let { value } = await props.updateEpisodeDetails(openModalData);
        if (get(value, "success", "") === true) {
          props.getShowDetailsById({
            id: get(props, "getShowDetailsByIdData", [])[0]?._id,
          });
          let time = setInterval(() => {
            openNotificationWithIcon(
              "success",
              "Update episode details successfully."
            );
            setEpisodeLoading(false);
            setOpenModal(false);
            setModalIsOpen(false);
            clearInterval(time);
          }, 3000);
          let times = setInterval(() => {
            setNewUpdateStatus(true);
            clearInterval(times);
          }, 2000);
        }
      }
    } else {
      setOnOffSaveButton(false);
      // console.log("ENTER222222222=========>");
      setEpisodeLoading(true);
      setEpisodeDetailsUpdate(true);
      let { value } = await props.updateEpisodeDetails(openModalData);
      if (get(value, "success", "") === true) {
        console.log("entry success part");
        props.getShowDetailsById({
          id: get(props, "getShowDetailsByIdData", [])[0]?._id,
        });
        let time = setInterval(() => {
          openNotificationWithIcon(
            "success",
            "Update episode details successfully."
          );
          setEpisodeLoading(false);
          setOpenModal(false);
          setModalIsOpen(false);
          clearInterval(time);
        }, 3000);
        let times = setInterval(() => {
          setNewUpdateStatus(true);
          clearInterval(times);
        }, 2000);
      }
    }
  };

  const validationCheck = async (value, key) => {
    if (selectSeasons === "0" && seasons.length > 0) {
      //   if (key === "assignSeason") {
      //     if (
      //       itunes === "" ||
      //       value === "" ||
      //       value === undefined ||
      //       openModalData.seasonName === "" ||
      //       openModalData.seasonName === undefined
      //     ) {
      //       setOnOffSaveButton(true);
      //     } else {
      //       setOnOffSaveButton(false);
      //     }
      //   } else if (key === "seasonName") {
      //     if (
      //       itunes === "" ||
      //       openModalData.assignSeason === "" ||
      //       openModalData.assignSeason === undefined ||
      //       value.trim() === "" ||
      //       value === undefined
      //     ) {
      //       setOnOffSaveButton(true);
      //     } else {
      //       setOnOffSaveButton(false);
      //     }
      //   } else {
      //     if (
      //       value.trim() === "" ||
      //       openModalData.assignSeason === "" ||
      //       openModalData.assignSeason === undefined ||
      //       openModalData.seasonName === "" ||
      //       openModalData.seasonName === undefined
      //     ) {
      //       setOnOffSaveButton(true);
      //     } else {
      //       setOnOffSaveButton(false);
      //     }
      //   }
      setOnOffSaveButton(false);
    } else {
      setOnOffSaveButton(false);
    }
  };

  const uploadSeasonImages = async () => {
    setLoading(true);
    let { value } = await props.updateSeasonImage({
      id: selectSeasons,
      url: imageUrl,
    });
    if (value.success) {
      props.resetLoginPhase();
      await props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      openNotificationWithIcon(
        "success",
        `${selectSeasonsData?.title} image uploaded successfully.`
      );
    }
    setImageModal(false);
    setLoading(false);
  };

  const handleChangeCategories = (index, data) => {
    const categories = selectSeasonCategories;
    var ind = data.children ? data.children : index;
    if (!categories.includes(data.value)) {
      categories.push(data.value);
      setSelectSeasonCategoriess([...categories]);
      // initialState["seasonCategories"] = categories;
      // setInitialState({ ...initialState });
    }
  };

  const handleRemoveCategories = (e, data) => {
    const categories = selectSeasonCategories;
    const index = categories.indexOf(e);

    if (index > -1) {
      categories.splice(index, 1);
      // initialState["seasonCategories"] = categories;
      setSelectSeasonCategoriess([...categories]);
    }
  };

  const updateSeasonCategories = async () => {
    setLoading(true);
    if (get(initialState, "seasonCategories", "") === "") {
    }
    let { value } = await props.updateSeasonCategories({
      id: selectSeasons,
      seasonCategories: selectSeasonCategories,
    });

    if (value.success) {
      props.resetLoginPhase();
      await props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      openNotificationWithIcon(
        "success",
        "Updated Season Categories successfully."
      );
    }
    setOpenCategoriesModal(false);
    setLoading(false);
  };

  const uploadSeasonCoverPhoto = async () => {
    setLoading(true);
    let { value } = await props.updateSeasonCoverPhoto({
      id: selectSeasons,
      url: seasonImageUrl,
    });
    if (value.success) {
      props.resetLoginPhase();
      await props.getShowDetailsById({
        id: get(props, "getShowDetailsByIdData", [])[0]?._id,
      });
      openNotificationWithIcon(
        "success",
        `${selectSeasonsData?.title} image uploaded successfully.`
      );
    }
    setSImageModal(false);
    setLoading(false);
  };

  const bytesToSize = (bytes) => bytes / (2048 * 2048);

  const addImages = async (acceptedFiles, keys) => {
    if (acceptedFiles.length > 0) {
      if (keys === "cover_image") {
        setCoverLoader(true);
      } else {
        setShowLoader(true);
      }
      setImageError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            // if (this.bytesToSize(data.size) <= 10) {
            // let dd = acceptedFiles[key];
            acceptedFiles[key].preview = URL.createObjectURL(data);
            //  cc.width = i.width / 2;
            //  cc.height = i.height / 2;
            const fileSize = (bytesToSize(acceptedFiles[key].size) * 10) / 100;
            const newFle = await imageCompression(acceptedFiles[key], {
              maxSizeMB: fileSize,
            });
            const file = new File([newFle], "image");
            if (keys == "cover_image") {
              let form_data = new FormData();
              form_data.append("cover_image", newFle);
              // let object = {};
              // form_data.forEach(function (value, key) {
              //   object[key] = value;
              // });
              // var json = JSON.stringify(object);
              // console.log(json, "json");
              let { value } = await props.uploadImages(form_data);
              setImage(value.url);
              setSeasonImageUrl(value.url);
              setCoverPreviewArr(data);
              setCoverLoader(false);
              // initialState['cover_image'] = data
              // setInitialState(initialState)
            } else {
              let form_data = new FormData();
              form_data.append("showImage", newFle);
              let { value } = await props.uploadImages(form_data);
              setImage(value.url);
              setSeasonImageUrl(value.url);
              setShowPreviewArr(data);
              setShowLoader(false);
              // initialState['showImage'] = data
              // setInitialState(initialState)
            }
          } else {
            setImageError("Please use jpeg or png format.");
            setCoverLoader(false);
            setShowLoader(false);
          }
        });
      }
    } else {
      setImageError("Please use jpeg or png format.");
      setCoverLoader(false);
      setShowLoader(false);
    }
  };

  const oModal = () => {
    setImageModal(true);
  };

  const seasonModal = () => {
    setSImageModal(true);
  };

  const categoriesModal = () => {
    setOpenCategoriesModal(true);
  };

  const hideModals = () => {
    setOpenHideModal(true);
  };

  const alertModal = (item) => {
    console.log(item, "item");
    setSelectedEpi(item);
    // setSelectedEpisodes(item)
    setShowAlertModal(true);
  };

  const statusAlertModals = (item) => {
    setSelectedEpi(item);
    setStatusAlertModal(true);
  };

  const closeModal = () => {
    setEpisodeLoading(false);
    setOpenModal(false);
    setHideModal(false);
    setModalIsOpen(false);
    setImageModal(false);
    setSImageModal(false);
    // setOpenCategoriesModal(false);
    setOpenHideModal(false);
    setShowAlertModal(false);
    setStatusAlertModal(false);
  };

  const closeOpenCategoryModal = () => {
    setOpenCategoriesModal(false);
  };

  const changeSeasons = async (key, i) => {
    setAllChecked(true);
    setAllCheckedPremium(false);
    setSeasonIndex(i);
    setImage("");
    setSeasonImageUrl("");
    setLoader(true);
    await setSelectedSeasons(key);
    let epidata;

    if (!orderingChange) {
      epidata = seasons.find((d) => d._id === key);
    } else {
      epidata = get(props, "getShowDetailsByIdData", [])[0]?.seasons.find(
        (d) => d._id === key
      );
    }
    console.log("epidata", epidata);
    setSelectedSeasonsData(epidata);
    await setImage(epidata?.image);
    await setSeasonImageUrl(epidata?.season_cover_photo);
    await setInitialState({ seasonCategories: epidata?.seasonCategories });
    setShowDetailButtonText(epidata?.showDetailButtonText);
    setFeaturedEpisodeId(epidata?.featuredEpisodeId);
    await setEpisodes(epidata?.episodesIds);
    setLoader(false);
    setLoadingData(true);
    let test = epidata?.episodesIds;
    console.log("epidata dadasd", epidata);
    // const data = test?.find((t) => t?._id.toString() === id.toString());

    // let newStatus = data?.episodeObjectImported?.attributes?.published;

    let newEpisode = [];
    let s = test?.map((t) => {
      if (
        !t?.episodeObjectImported?.attributes?.published &&
        t?.episodeNumber
      ) {
        newEpisode.push(t);
      }
    });

    if (newEpisode.length > 0) {
      await Promise.all(s);
    }
    let newEpisode2 = [];
    let ss = test?.map((t) => {
      if (t?.episodeNumber) {
        newEpisode2.push(t);
      }
    });

    if (newEpisode2.length > 0) {
      await Promise.all(s);
    }
    setSelectedEpisodes([...newEpisode]);
    if (newEpisode?.length === newEpisode2?.length && newEpisode2?.length > 0) {
      setAllChecked(false);
    }

    let newEpisodes = [];
    let y = test?.map((u) => {
      if (u?.episodePremium && u?.episodeNumber) {
        newEpisodes.push(u);
      }
    });

    if (newEpisodes.length > 0) {
      await Promise.all(y);
    }
    let newEpisodes2 = [];
    let kk = test?.map((s) => {
      if (s?.episodeNumber) {
        newEpisodes2.push(s);
      }
    });

    if (newEpisodes2.length > 0) {
      await Promise.all(y);
    }
    setSelectedEpiPremium([...newEpisodes]);

    if (
      newEpisodes?.length === newEpisodes2?.length &&
      newEpisodes2?.length > 0
    ) {
      setAllCheckedPremium(true);
    }

    let time = setInterval(async () => {
      setLoadingData(false);
      clearInterval(time);
    }, 2000);
  };

  const updateShowDetails = async () => {
    setLoading(true);
    if (featuredEpisodeId === null) {
      setLoading(false);
    } else if (showDetailButtonText === "") {
      setLoading(false);
    } else {
      let initialState = {
        _id: get(props, "getShowDetailsByIdData", [])[0]?._id,
        featuredSeasonId: selectSeasons,
        // featuredEpisodeId: selectedEpisode,
        seasonFeaturedPodcast: {
          isSeason: isSeason,
          showDetailButtonText: showDetailButtonText,
          featuredSeasonId: selectSeasons,
          featuredEpisodeId: featuredEpisodeId,
        },
      };
      props.updateShowDetails(initialState);
    }
  };

  const handleTextChange = (name, value) => {
    setShowDetailButtonText(value);
  };

  const episodeStatus = [
    { key: "episode", value: "Episode" },
    { key: "trailer", value: "Trailer/Teaser" },
  ];
  const onSelectedStatus = (key, value) => {
    setIsEpisode(value);
  };

  const episodeCount = filter(episodes, function (v) {
    return v.episodeNumber !== null;
  });

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row">
            <div className="page__header--left">
              <div className="mb-3 d-flex align-items-end">
                {(episodes?.length > 0 || seasons?.length > 0) && (
                  <div>
                    <label className="label-primary">Select Season</label>
                    <select
                      name="season"
                      value={selectSeasons}
                      onChange={(e) => changeSeasons(e.target.value)}
                      className="form-control custom-select material-textfield-input"
                      required
                      style={{ width: 150 }}
                    >
                      {/* <option value="0">Select</option> */}
                      <option value="0">Season 0</option>
                      {seasons?.map((d, i) => {
                        return (
                          <option key={i} value={d._id}>
                            {d.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {selectSeasons !== "0" && (
                  <div className="ms-3 mb-2">
                    Number of Episodes: {episodeCount?.length}
                  </div>
                )}

                {/* {console.log("nullEpisodes?.length",nullEpisodes)} */}
                {selectSeasons === "0" && (
                  <div className="ms-3 mb-2">
                    Number of Episodes: {nullEpisodes?.length}
                  </div>
                )}
              </div>
            </div>
            {selectSeasons !== "0" && seasons.length > 0 && (
              <div className="page__header--right">
                <button
                  type="button"
                  onClick={() => opModal(selectSeasons)}
                  className="btn btn__default"
                >
                  <Setting className="btn__icon" /> Season Featured Podcast
                </button>
                {selectSeasons !== "0" && seasons.length > 0 && (
                  <button
                    type="button"
                    onClick={oModal}
                    className="btn btn__default"
                  >
                    Season Photo
                  </button>
                )}
                {selectSeasons !== "0" && seasons.length > 0 && (
                  <button
                    type="button"
                    onClick={seasonModal}
                    className="btn btn__default"
                  >
                    Season Cover Photo
                  </button>
                )}
                {selectSeasons !== "0" && seasons.length > 0 && (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectSeasonCategoriess([
                        ...get(initialState, "seasonCategories", []),
                      ]);
                      categoriesModal();
                    }}
                    className="btn btn__default"
                  >
                    Assign Categories
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom draggable-table--new">
          {loadingData ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
              }
              className="loader__full"
            />
          ) : (
            <div className="table table__custom drag-table-container all-select-checkbox-container">
              <div className="draggable-continer-head align-items-center justify-content-evenly">
                <div className="title-block">HEADING</div>
                <div className="hide-app-icon-td">PREMIUM EPISODE</div>
                {/* <div className="hide-app-icon-td"></div> */}
                {selectSeasons === "0" && (
                  <div className="hide-app-icon-td">HIDE IN APP</div>
                )}
                {selectSeasons !== "0" && (
                  <div className="hide-app-icon-td">HIDE IN APP</div>
                )}
                <div className="action-description">Last Updated Date</div>
                <div className="action-description"></div>
              </div>
              {episodeCount?.length > 0 && selectSeasons !== "0" && (
                // <Droppable droppableId="droppable">
                <div className="timeline-block scroll-block drag-table-container">
                  <div
                    className="draggable-row"
                    style={{
                      //   backgroundColor: "black",
                      flex: 1,
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    // className="draggable-row justify-content-between d-flex"
                  >
                    <div
                      className="title-block"
                      //   style={{ backgroundColor: "blue" }}
                    ></div>
                    {/* <div
                    className="hide-app-icon-td checkbox-one-items d-flex align-items-center"
                    style={{ backgroundColor: "red", right: 10 }}
                  > */}
                    <div
                      className="single-checkbox-tab align-content-center d-flex"
                      //   style={{ backgroundColor: "yellow" }}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="custom__checkbox--input"
                          checked={allCheckedPremium}
                          onChange={(e) => onCheckPremium({}, "All")}
                          name="duplex"
                        />
                      </div>
                    </div>

                    <div
                      className="single-checkbox-tab align-content-center d-flex"
                      //   style={{ backgroundColor: "red" }}
                    >
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="custom__checkbox--input"
                          checked={!allChecked}
                          onChange={(e) => {
                            !allChecked ? onCheck({}, "All") : hideModals();
                          }}
                          // onChange={(e) =>  hideModals()}
                          name="duplex"
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end align-items-center"
                      style={{ visibility: "hidden" }}
                    >
                      <div className="hide-ep-block me-3 d-flex align-items-center"></div>
                      25/06/2004, 12:34 AM
                    </div>
                    {/* <div>25/06/2004</div> */}
                    {/* <div
                      className="action-description"
                      style={{ backgroundColor: "red" }}
                    ></div> */}
                    {/* <div
                      className="description-column"
                      style={{ backgroundColor: "GrayText" }}
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="hide-ep-block me-3 d-flex align-items-center">
                          {" "}
                        </div>
                      </div>
                    </div>*/}
                    <div className="description-column">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="hide-ep-block me-3 d-flex align-items-center"></div>
                      </div>
                    </div>
                    {/* <div
                      className="single-checkbox-tab align-content-center d-flex"
                      style={{ backgroundColor: "red" }}
                    >
                      <div className="d-flex align-items-center"></div>
                    </div>
                    <div
                      className="single-checkbox-tab align-content-center d-flex"
                      style={{ backgroundColor: "red" }}
                    >
                      <div className="d-flex align-items-center"></div>
                    </div> */}
                  </div>
                </div>
              )}

              {selectSeasons !== "0" && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        className="timeline-block scroll-block drag-table-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {episodes?.map((e, i) => {
                          // console.log(
                          //   moment(e?.updatedAt).format("MM/DD/YYYY, hh:mm"),
                          //   "<<<<"
                          // );
                          if (e.episodeNumber) {
                            return (
                              <Draggable
                                key={e._id}
                                draggableId={e._id}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="draggable-row justify-content-between"
                                    key={e._id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    provided={provided}
                                    snapshot={snapshot}
                                  >
                                    <div
                                      className="title-block"
                                      //   style={{ backgroundColor: "blue" }}
                                    >
                                      <DragIcon className="drag-icon" />
                                      <button
                                        onClick={() => {
                                          setOpenModalData({});
                                          setModalIsOpen(true);

                                          setOpenModalData({ ...e });
                                        }}
                                        className="btn"
                                      >
                                        <Eye />
                                      </button>{" "}
                                      {e?.seasonName && <> {e?.seasonName}</>}
                                      {e.episodeName && (
                                        <> {e.episodeName} - </>
                                      )}{" "}
                                      {e.title}
                                    </div>

                                    <div
                                      className="single-checkbox-tab align-content-center d-flex"
                                      //   style={{ backgroundColor: "yellow" }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="custom__checkbox--input"
                                          name="duplex"
                                          value={i}
                                          checked={
                                            selectedEpiPremium?.findIndex(
                                              (t) =>
                                                t?._id?.toString() ===
                                                e._id?.toString()
                                            ) !== -1
                                          }
                                          onChange={() => onCheckPremium(e)}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className="single-checkbox-tab align-content-center d-flex"
                                      //   style={{ backgroundColor: "red" }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="custom__checkbox--input"
                                          name="duplex"
                                          value={i}
                                          checked={
                                            // selectedEpisodes?.map((l) => l._id.toString())?.indexOf(e._id.toString()) !==
                                            // -1
                                            selectedEpisodes?.findIndex(
                                              (t) =>
                                                t?._id?.toString() ===
                                                e._id?.toString()
                                            ) !== -1
                                            //  ||
                                            //   !e?.episodeObjectImported
                                            //   ?.attributes?.published ===
                                            // false
                                            //   ? false
                                            //   : true
                                          }
                                          // onChange={() => onCheck(e)}
                                          onChange={() => {
                                            if (
                                              // featuredEpisode?._id.toString() === e._id.toString() &&
                                              // e?.episodeObjectImported?.attributes?.published === true &&
                                              selectSeasonsData?.featuredEpisodeId?.toString() ===
                                              e?._id?.toString()
                                            ) {
                                              alertModal(e);
                                            } else if (
                                              featuredEpisode?._id?.toString() ===
                                              e._id?.toString()
                                            ) {
                                              alertModal(e);
                                            } else if (
                                              e?.episodeObjectImported
                                                ?.attributes?.published ===
                                                false &&
                                              e?.totaltTimeForAdFeedUrl ===
                                                "" &&
                                              e?.totaltTime === ""
                                            ) {
                                              statusAlertModals(e);
                                            } else {
                                              onCheck(e);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {/* <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ right: 10 }}
                                    >
                                      <div className="hide-ep-block me-3 d-flex align-items-start"></div>
                                      {moment(e?.updatedAt).format(
                                        "MM/DD/YYYY, hh:mm A"
                                      )}
                                    </div> */}
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ right: 10 }}
                                    >
                                      <div className="hide-ep-block me-3 d-flex align-items-start"></div>
                                      {moment(e?.createdAt).format(
                                        "MM/DD/YYYY, hh:mm A"
                                      )}
                                    </div>
                                    <div className="description-column">
                                      <div className="d-flex justify-content-end align-items-center">
                                        <div className="hide-ep-block me-3 d-flex align-items-center"></div>

                                        <button
                                          onClick={() => {
                                            setOpenModalData({});
                                            setModalIsOpen(true);
                                            setOpenModalData({ ...e });
                                          }}
                                          className="btn me-3"
                                        >
                                          <Edit />
                                        </button>
                                        <ActionMoreComponent
                                          openModalHide={openModalHide}
                                          setModalIsOpen={setModalIsOpen}
                                          setOpenModalData={setOpenModalData}
                                          data={e}
                                          keyS="test"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {nullEpisodes?.length > 0 && selectSeasons === "0" && (
                <DragDropContext onDragEnd={onDragEndForNullEpisode}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        className="timeline-block scroll-block drag-table-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {nullEpisodes?.map((ep, i) => {
                          return (
                            <Draggable
                              key={ep._id}
                              draggableId={ep._id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="draggable-row justify-content-between"
                                  key={ep._id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  provided={provided}
                                  snapshot={snapshot}
                                >
                                  <div className="title-block">
                                    <DragIcon className="drag-icon" />
                                    <button
                                      onClick={() => {
                                        setOpenModalData({});
                                        setModalIsOpen(true);
                                        setItunes(
                                          ep.episodeObjectImported.attributes
                                            .itunes_type === "full"
                                            ? "teaser"
                                            : ep.episodeObjectImported
                                                .attributes.itunes_type
                                        );
                                        setOpenModalData({ ...ep });
                                      }}
                                      className="btn me-2"
                                    >
                                      <Eye />
                                    </button>{" "}
                                    {ep?.seasonName && <> {ep?.seasonName}</>}
                                    {
                                      <>
                                        {" "}
                                        {ep.episodeObjectImported.attributes
                                          .itunes_type === "full"
                                          ? "teaser"
                                          : ep.episodeObjectImported.attributes
                                              .itunes_type}{" "}
                                        -{" "}
                                      </>
                                    }{" "}
                                    {ep.title}
                                    {/* {ep?.seasonName && <> {ep?.seasonName}</>}
                                      { itunes  && (
                                        <> {itunes  === "full" ? "teaser" : itunes } - </>
                                      )}{" "} */}
                                    {/* {ep.title} */}
                                  </div>
                                  <div className="single-checkbox-tab align-content-center d-flex">
                                    {/* <div className="single-checkbox-tab align-content-center d-flex"></div> */}
                                    <div className="single-checkbox-tab align-content-center d-flex">
                                      <input
                                        type="checkbox"
                                        //value={e.episodeObjectImported.attributes.published}
                                        onChange={() =>
                                          premiumForSeasonZero(ep._id)
                                        }
                                        id="subscribe"
                                        name="subscribe"
                                        //checked={e.episodeObjectImported.attributes.published}
                                        //checked
                                        className="custom__checkbox--input"
                                        style={{
                                          display: "flex",
                                          alignSelf: "center",
                                        }}
                                        checked={
                                          ep?.episodePremium === true
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="single-checkbox-tab align-content-center d-flex"></div> */}
                                  <div className="hide-app-icon-td checkbox-two-items d-flex align-items-center ms-0">
                                    <input
                                      type="checkbox"
                                      //value={e.episodeObjectImported.attributes.published}
                                      // onChange={() =>
                                      //   handleChangeForSeasonZero(ep._id)
                                      // }

                                      onChange={() => {
                                        if (
                                          epiFeature === ep._id &&
                                          ep?.episodeObjectImported?.attributes
                                            ?.published === true
                                        ) {
                                          alertModal(ep);
                                        } else if (
                                          ep?.episodeObjectImported?.attributes
                                            ?.published === false &&
                                          ep?.totaltTimeForAdFeedUrl === "" &&
                                          ep?.totaltTime === ""
                                        ) {
                                          statusAlertModals(ep);
                                        } else {
                                          handleChangeForSeasonZero(ep._id);
                                        }
                                      }}
                                      id="subscribe"
                                      name="subscribe"
                                      //checked={e.episodeObjectImported.attributes.published}
                                      //checked
                                      checked={
                                        !ep?.episodeObjectImported?.attributes
                                          ?.published === false
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ right: 10 }}
                                  >
                                    <div className="hide-ep-block me-3 d-flex align-items-start"></div>
                                    {moment(ep?.createdAt).format(
                                      "MM/DD/YYYY, hh:mm A"
                                    )}
                                  </div>
                                  <div className="description-column">
                                    <div className="d-flex justify-content-end">
                                      <div className="single-checkbox-tab align-content-center d-flex"></div>

                                      <button
                                        onClick={() => {
                                          setOpenModalData({});
                                          setModalIsOpen(true);
                                          setItunes(
                                            ep.episodeObjectImported.attributes
                                              .itunes_type === "full"
                                              ? "teaser"
                                              : ep.episodeObjectImported
                                                  .attributes.itunes_type
                                          );
                                          setOpenModalData({ ...ep });
                                        }}
                                        className="btn me-3"
                                      >
                                        <Edit />
                                      </button>
                                      <ActionMoreComponent
                                        openModalHide={openModalHide}
                                        setModalIsOpen={setModalIsOpen}
                                        setOpenModalData={setOpenModalData}
                                        data={ep}
                                        keyS="test"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          )}
        </div>

        {episodeCount?.length === 0 && selectSeasons !== "0" && (
          // openModalData.appDisplayStatus === "active" &&
          <div className="table__blank">
            <div className="table__blank--text">No data found</div>
          </div>
        )}
        {nullEpisodes?.length === 0 && selectSeasons === "0" && (
          <div className="table__blank">
            <div className="table__blank--text">No data found</div>
          </div>
        )}
        {/* <TablePaginationComponent
          Results="10"
          Previous="Previous"
          Next="Next"
        /> */}
      </div>

      <ReactModal
        isOpen={openModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Season Featured Podcast</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <div className="custom__radio__group">
                {episodeStatus.map((d, i) => {
                  return (
                    <>
                      <input
                        className="custom__radio__group--button"
                        id={d.key}
                        type="radio"
                        checked={isEpisode === d.key}
                        onClick={() => onSelectedStatus("isEpisode", d.key)}
                      />
                      <label
                        className="custom__radio__group--label"
                        htmlFor={d.key}
                      >
                        {d.value}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
            {isEpisode === "episode" ? (
              <div className="form-group">
                <label className="label-primary">
                  Show Detail: Select Featured Season Episode
                </label>
                <select
                  name="featuredEpisodeId"
                  value={featuredEpisodeId}
                  onChange={(e) => {
                    setFeaturedEpisodeId(e.target.value);
                  }}
                  className="form-control custom-select material-textfield-input"
                  required
                  style={{ width: 150 }}
                >
                  <option value="0">Select</option>
                  {episodes?.map((d, i) => {
                    if (
                      d.episodeNumber &&
                      d.episodeObjectImported.attributes.published === true
                    ) {
                      return (
                        <option key={i} value={d._id}>
                          {d.title}
                        </option>
                      );
                    }
                  })}
                </select>
                {(featuredEpisodeId === "0" || featuredEpisodeId === null) && (
                  <div className="invalid-feedback">
                    please assign feature episode
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group">
                <label className="label-primary">
                  Show Detail: Select Featured Trailer/Teaser
                </label>
                <select
                  name="featuredEpisodeId"
                  value={featuredEpisodeId}
                  onChange={(e) => {
                    setFeaturedEpisodeId(e.target.value);
                  }}
                  className="form-control custom-select material-textfield-input"
                  // required
                  style={{ width: 150 }}
                >
                  <option value="0">Select</option>
                  {episodes?.map((d, i) => {
                    if (!d.episodeNumber) {
                      return (
                        <option key={i} value={d._id}>
                          {d.title}
                        </option>
                      );
                    }
                  })}
                </select>
                {/* {(featuredEpisodeId === "") && (
                <div className="invalid-feedback">please assign feature episode</div>
              )} */}
              </div>
            )}
            <div className="form-group">
              {/* <label className="label-primary">
                Show Detail: Featured Podcast Button Text{" "}
              </label> */}
              <label className="label-primary image-space">
                Show Detail: Featured Podcast Button Text{" "}
                <Popover
                  overlayClassName="info-overlay"
                  placement="topLeft"
                  content={popoverImage}
                  trigger="hover"
                >
                  <Info className="icon-small-v" />
                </Popover>
              </label>
              <input
                type="text"
                value={showDetailButtonText}
                name="showDetailButtonText"
                onChange={(e) =>
                  handleTextChange(e.target.name, e.target.value)
                }
                className="form-control material-textfield-input"
                required
              />
              {showDetailButtonText === "" && (
                <div className="invalid-feedback">Please enter button text</div>
              )}
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              onClick={() => updateShowDetails()}
              type="submit"
              className="btn btn__purple"
            >
              {loading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Episode Settings</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">
                Home: Set Featured Podcast
              </label>
              <select
                name="country"
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                <option value="0">S1</option>
                <option value="0">S2</option>
              </select>
              <div className="invalid-feedback">dsvsdvds</div>
            </div>
            <div className="form-group">
              <label className="label-primary">
                Show Detail: Set Featured Podcast
              </label>
              <select
                name="country"
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                <option value="0">S1</option>
                <option value="0">S2</option>
              </select>
              <div className="invalid-feedback">dsvsdvds</div>
            </div>
            <div className="form-group">
              <label className="label-primary">
                Featured Photo (recommended dimensions)
              </label>
              <select
                name="country"
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                <option value="0">S1</option>
                <option value="0">S2</option>
              </select>
              <div className="invalid-feedback">dsvsdvds</div>
            </div>

            <div className="form-group">
              <label className="label-primary">
                Show Detail: Featured Podcast Button Text{" "}
              </label>
              <input
                type="text"
                name=""
                className="form-control material-textfield-input"
                required
              />
            </div>
          </div>
          <div className="react-modal-footer">
            <button type="button" className="btn btn__default me-2">
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Save
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={hideModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title ">Hide/UnHide</h5>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-body-text">
              Are you sure do you want to{" "}
              {openModalData.appDisplayStatus === "active" ? "Hide" : "UnHide"}{" "}
              this ?
            </p>
          </div>
          <div className="react-modal-footer">
            <button onClick={closeModal} className="btn btn__default">
              Cancel
            </button>
            <button
              onClick={() => updateEpisodeDetails()}
              className="btn btn__purple ms-2"
            >
              Yes
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Episode Detail</h5>
          </div>
          <div className="react-modal-body">
            {selectSeasons === "0" && seasons.length > 0 && (
              <div className="form-group">
                <label className="label-primary">Assign Season</label>
                <select
                  onChange={(e) => assignSeason(e.target.value)}
                  value={get(openModalData, "assignSeason", "")}
                  //value={selectDefaultSeason}
                  className="form-control custom-select material-textfield-input"
                  required
                >
                  <option value="">Select</option>
                  {seasons?.map((data, index) => (
                    <option key={index} value={data._id}>
                      {data.title}
                    </option>
                  ))}
                </select>
                {/* {get(openModalData, "assignSeason", "") === "" && (
                  <div className="invalid-feedback">Please assign season</div>
                )} */}
              </div>
            )}

            {selectSeasons === "0" && seasons.length > 0 && (
              <div className="form-group">
                <label className="label-primary">Season Name</label>
                <input
                  type="text"
                  name="seasonName"
                  onChange={(e) =>
                    handleTextChangeEpisode(e.target.name, e.target.value)
                  }
                  value={get(openModalData, "seasonName", "")}
                  className="form-control material-textfield-input"
                  required
                />
                {/* {get(openModalData, "seasonName", "") === "" && (
                  <div className="invalid-feedback">
                    Please enter season name
                  </div>
                )} */}
              </div>
            )}
            {selectSeasons !== "0" && (
              <div className="form-group">
                <label className="label-primary">Season Name</label>
                <input
                  type="text"
                  name="seasonName"
                  onChange={(e) =>
                    handleTextChangeEpisode(e.target.name, e.target.value)
                  }
                  value={get(openModalData, "seasonName", "")}
                  className="form-control material-textfield-input"
                />
              </div>
            )}
            {selectSeasons !== "0" && (
              <div className="form-group">
                <label className="label-primary">Episode Name</label>
                <input
                  type="text"
                  name="episodeName"
                  onChange={(e) =>
                    handleTextChangeEpisode(e.target.name, e.target.value)
                  }
                  value={get(openModalData, "episodeName", "")}
                  className="form-control material-textfield-input"
                />
              </div>
            )}

            {selectSeasons === "0" && seasons.length > 0 && (
              <div className="form-group">
                <label className="label-primary">Episode Name</label>
                <input
                  type="text"
                  name="itunes_type"
                  onChange={(e) =>
                    handleTextChangeEpisode(e.target.name, e.target.value)
                  }
                  value={itunes}
                  // value={itunes === "full" ? "teaser" : itunes}
                  className="form-control material-textfield-input"
                  required
                />
                {/* {get(openModalData, "episodeName", "") === "" && (
                  <div className="invalid-feedback">
                    Please enter episode name
                  </div>
                )} */}
                {(itunes === "" || !itunes) && (
                  <div className="invalid-feedback">
                    Please enter episode name
                  </div>
                )}
              </div>
            )}
            <div className="form-group">
              <label className="label-primary">Episode Title</label>
              <input
                type="text"
                name="title"
                onChange={(e) =>
                  handleTextChangeEpisode(e.target.name, e.target.value)
                }
                value={get(openModalData, "title", "")}
                className="form-control material-textfield-input"
                required
              />
              {get(openModalData, "title", "") === "" && (
                <div className="invalid-feedback">Please enter title</div>
              )}
            </div>

            <div className="form-group">
              <label className="label-primary">Episode Pusblished Date</label>
              {/*<input
                type="text"
                name="publishedAt"
                onChange={()=>console.log()}
                value={get(openModalData,'publishedAt','')}
                className="form-control material-textfield-input"
                required
              />*/}
              <div>
                <DatePicker
                  className="form-control material-textfield-input"
                  selected={new Date(get(openModalData, "publishedAt", ""))}
                  onChange={(date) => {
                    openModalData["publishedAt"] = date;
                    setOpenModalData({ ...openModalData });
                  }}
                />
              </div>
              {get(openModalData, "publishedAt", "") === "" && (
                <div className="invalid-feedback">
                  Please enter published date
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="label-primary">
                Episode Short Description{" "}
              </label>
              <textarea
                type="text"
                name="shortDescription"
                onChange={(e) =>
                  handleTextChangeEpisode(e.target.name, e.target.value)
                }
                value={get(openModalData, "shortDescription", "")}
                className="form-control material-textfield-input"
                required
                rows={3}
              ></textarea>
              {get(openModalData, "shortDescription", "") === "" && (
                <div className="invalid-feedback">
                  Please enter short description
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="label-primary">Episode Description </label>
              <textarea
                type="text"
                name="descriptionPlain"
                onChange={(e) =>
                  handleTextChangeEpisode(e.target.name, e.target.value)
                }
                value={get(openModalData, "descriptionPlain", "")}
                className="form-control material-textfield-input"
                required
                rows={10}
              ></textarea>
              {get(openModalData, "descriptionPlain", "") === "" && (
                <div className="invalid-feedback">Please enter description</div>
              )}
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={closeModal}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              disabled={onOffSaveButton}
              onClick={() => updateEpiDetails()}
              type="submit"
              className="btn btn__purple"
            >
              {episodeLoading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={openImageModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={true}
        // shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title image-space">
              Upload Image for {selectSeasonsData?.title}
              <Popover
                overlayClassName="info-overlay"
                placement="topLeft"
                content={seasonImg}
                trigger="hover"
              >
                <Info className="icon-small-v" />
              </Popover>
            </h5>
            {/* <h5 className="react-modal-title">
              Upload Image for {selectSeasonsData?.title}
            </h5> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) =>
                  addImages(acceptedFiles, "cover_image")
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {coverLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {get(coverPreviewArr, "preview", "") === "" && !imageUrl && (
                <div className="invalid-feedback">
                  Please upload cover image.
                </div>
              )}
              {imageUrl && imageUrl !== "" && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img className="preview__thumb" src={imageUrl} alt="" />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setCoverPreviewArr({});
                            setImage("");
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              onClick={() => uploadSeasonImages()}
              type="button"
              className="btn btn__purple"
            >
              {loading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={openSImageModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={true}
        // shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title image-space">
              Upload season cover photo for {selectSeasonsData?.title}
              <Popover
                overlayClassName="info-overlay"
                placement="topLeft"
                content={seasonCoverImg}
                trigger="hover"
              >
                <Info className="icon-small-v" />
              </Popover>
            </h5>
            {/* <h5 className="react-modal-title">
              Upload season cover photo for {selectSeasonsData?.title}
            </h5> */}
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) =>
                  addImages(acceptedFiles, "cover_image")
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {coverLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {get(coverPreviewArr, "preview", "") === "" &&
                !seasonImageUrl && (
                  <div className="invalid-feedback">
                    Please upload season cover photo.
                  </div>
                )}
              {/* {console.log("seasonImageUrl", seasonImageUrl)} */}
              {seasonImageUrl && seasonImageUrl !== "" && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={seasonImageUrl}
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setCoverPreviewArr({});
                            setSeasonImageUrl("");
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              onClick={() => uploadSeasonCoverPhoto()}
              type="button"
              className="btn btn__purple"
            >
              {loading ? <Spin indicator={antIcon} /> : "Save"}
            </button>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={openCategoriesModal}
        onRequestClose={closeOpenCategoryModal}
        contentLabel=""
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={true}
        // shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Assign Categories</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Select Categories </label>
              <Select
                value={selectSeasonCategories}
                mode="tags"
                style={{ width: "100%" }}
                className="multiple__tags__textfield"
                // placeholder="Enter Phone, if sending to multiple people use a comma to separate the emails"
                // onSearch={this.onSearchMail.bind(this)}
                onSelect={handleChangeCategories}
                onDeselect={handleRemoveCategories}
                filterOption={(input, option) =>
                  option.props.value
                    ?.toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {get(props, "categoryData", []).length > 0 &&
                  get(props, "categoryData", []).map((d, key) => {
                    if (d.status !== "inactive") {
                      return (
                        <>
                          {
                            <Option key={key} value={get(d, "_id", "")}>
                              {get(d, "name", "")}
                            </Option>
                          }
                        </>
                      );
                    }
                  })}
              </Select>
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => closeOpenCategoryModal()}
                type="button"
                className="btn btn__default me-2"
              >
                Cancel
              </button>
              <button
                onClick={() => updateSeasonCategories()}
                type="button"
                className="btn btn__purple"
              >
                {loading ? <Spin indicator={antIcon} /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={openHideModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Bulk Assign</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">
                {/* {console.log("11111111111", get(props, "getShowDetailsByIdData", [])[0]?.featuredEpisodeId[0]?.seasonId?.toString() !== selectSeasonsData?._id?.toString())} */}
                {featuredSeasonId !== selectSeasonsData?._id?.toString() && (
                  <>
                    Are you sure you want to bulk hide. This will apply to all
                    episodes.
                  </>
                )}

                {featuredSeasonId?.toString() ===
                  selectSeasonsData?._id?.toString() && (
                  <>
                    <b>{featuredTitle} </b>
                    is a “Featured” episode; before you can hide it, please
                    select a different episode as “Featured”.
                  </>
                )}
              </label>
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            {/* <button type="submit" className="btn btn__purple">
              Save
            </button> */}
            {featuredSeasonId !== selectSeasonsData?._id?.toString() && (
              <button
                onClick={() => onCheck({}, "All")}
                type="submit"
                className="btn btn__purple"
              >
                Bulk Assign
              </button>
            )}
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showAlertModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">
              You’re about to hide a Featured item
            </h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">
                This a “Featured” episode; before you can hide it, please select
                a different episode as “Featured”.
              </label>
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Okay
            </button>
            {/* <button
             onClick={() => {
              onCheck(selectedEpi);
              closeModal();
            }}
             
              type="submit"
              className="btn btn__purple"
            >
             continue to hide 
            </button> */}
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={statusAlertModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Alert!!</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">
                {" "}
                Unable to Unhide this Episode. This episode does not contain
                media play time and cannot be played in the app.
              </label>
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Okay
            </button>
            {/* <button
             onClick={() => {
              onCheck(selectedEpi);
              closeModal();
            }}
             
              type="submit"
              className="btn btn__purple"
            >
             continue to hide 
            </button> */}
          </div>
        </div>
      </ReactModal>
    </>
  );
}
