import React, { useState, useEffect, useCallback } from "react";
import { Close } from "../../../components/icons";
import { get, isElement, isEmpty, orderBy, size } from "lodash";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { async } from "rxjs";
let cancelTokenUserList;
let cancelToken;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function NewShowFromArt19Component(props) {
  const [list, setList] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [nextStep, setNextStep] = useState(0);
  const [userQuery, setUserQuery] = useState("");
  const [loaderUser, setLoaderUser] = useState(true);
  const [page, setPage] = useState(1);
  const [hide, setHide] = useState(true);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const onSave = () => {
    openNotificationWithIcon("success", "Saved changes successfully.");
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleUserChange = async (name, q) => {
    setHide(true);
    setLoaderUser(true);
    setUserQuery(q);
    if (typeof cancelTokenUserList != typeof undefined) {
      cancelTokenUserList.cancel("Operation canceled due to new request.");
    }
    let pageNum = 1;
    setPage(pageNum);
    const { value } = await props.getList({
      pageNumber: pageNum,
      pageSize: 10,
      text: q,
    });
    console.log(value, "valueeeeeeeeeeeeee");
    if (get(value, "success", "") === true) {
      setList(value?.data);
      setLoaderUser(false);
      if (q === "") {
        setHide(true);
      } else {
        setHide(false);
      }
    } else {
      setList(value?.data);
      setLoaderUser(false);
      setHide(false);
    }
  };

  const optimizedUserFn = useCallback(debounce(handleUserChange), []);
  useEffect(() => {
    const callApi = async () => {
      const { value } = await props.getList({
        pageNumber: 1,
        pageSize: 10,
        text: "",
      });
      if (get(value, "success", "") === true) {
        setList(value?.data);
        // setHide(true);
        setLoaderUser(false);
      }
    };
    callApi();
  }, []);

  const navigate = useNavigate();

  const pageChange = async (value) => {
    if (value === "next") {
      setLoaderUser(true);
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.getList({
        pageNumber: pageNum,
        pageSize: 10,
        text: "",
      });
      if (get(value, "success", "") === true) {
        setList(value?.data);
        // setHide(false);
        setLoaderUser(false);
        //   setHide(false);
      }
    } else if (value === "previous") {
      // let prev = page - 2
      // setPreviousStep(prev)
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.getList({
        pageNumber: pageNum,
        pageSize: 10,
        text: "",
      });
      if (get(value, "success", "") === true) {
        setList(value?.data);
        // setHide(false);
        setLoaderUser(false);
        // setHide(false);
      }
    }
  };

  const syncRecords = async (id) => {
    setLoaderUser(true);
    const { value } = await props.syncSeriesRecords({ id: id });
    if (get(value, "success", "") === true) {
      setList(value?.data);
      // setLoaderUser(false);
    }
    openNotificationWithIcon("success", "Sync Series successfully.");
    if (get(value, "success", "") === false) {
      console.log("heyyyyyyyyyyyy", value);
      setList(value?.data);
      // setLoaderUser(false);
    }
    setLoaderUser(false);
    openNotificationWithIcon(
      "error",
      "Syncing failed(Not found appropriate data in art19)."
    );
  };

  return (
    <>
      <div className="custom__container">
        <div className="main__header">
          <header class="head">
            <div class="head__container">
              <div class="head__row">
                <div class="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/shows")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div class="head__divider"></div>
                  <div class="head__title">Art19 Shows</div>
                </div>
                <div className="head__right">
                  <button
                    type="button"
                    className="btn btn__green"
                    onClick={() => {
                      navigate("/shows");
                      onSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </header>
        </div>

        <div className="container-small small-table-block">
          <div className="form-group">
            {/* <input
                    type="text"
                    name=""
                    placeholder="Search by name, email, or phone number..."
                    className="form-control"
                    required
                    /> */}
            <input
              name="userQuery"
              onChange={(e) => {
                setUserQuery(e.target.value);
                optimizedUserFn(e.target.name, e.target.value);
              }}
              // onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={userQuery}
              // onfocusout={myFunc}
              placeholder="Search by show name..."
              className="form-control"
            />
          </div>
          <div className="custom__container">
            <div className="table-responsive table__responsive--custom">
              {loaderUser ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                <table className="table table__custom drag-table-container">
                  <thead>
                    <th className="title-block">ALL ART19 SHOWS</th>
                    <th className="status">STATUS</th>
                    <th className="action-description text-center">
                      SYNC TO DATABASE
                    </th>
                  </thead>
                  <tbody>
                    {list.length > 0 &&
                      list.map((l, i) => (
                        <tr key={i}>
                          <td style={{whiteSpace: 'normal'}}>{l.title}</td>

                          {l?.status === "Not Synced" ? (
                            <td
                              className="table__status not-active"
                              style={{ color: "#FF69B4" }}
                            >
                              {l.status}
                            </td>
                          ) : (
                            <td
                              className="table__status active"
                              style={{ color: "#5ABDCE" }}
                            >
                              {l.status}
                            </td>
                          )}
                          <td className="text-center">
                            {l?.status === "Not Synced" && (
                              <button
                                style={{ color: "#5ABDCE" }}
                                className="sync-btn"
                                // className="table__status active"
                                onClick={() => syncRecords(l?.id)}
                              >
                                {/* sync */}
                                <SyncOutlined />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {list.length > 0 && (
                <div className="table__pagination">
                  <div className="table__pagination--results">
                    {size(list)} results
                  </div>
                  <div className="table__pagination--nav">
                    <>
                      {page - 1 >= 1 ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev"
                          onClick={() => pageChange("previous")}
                        >
                          Previous
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Previous
                        </button>
                      )}
                    </>

                    <>
                      {!hide ? (
                        <button
                          type="button"
                          className="btn btn__default table__pagination--prev disabled"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="me-1 btn btn__default table__pagination--next"
                          onClick={() => pageChange("next")}
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              )}
              {list?.length === 0 && (
                <div className="table__blank">
                  <div className="table__blank--text">No Data</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*  </main>
       */}
    </>
  );
}
