import { Record } from "immutable";
import { assign } from "lodash";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";

// import { fromPromise } from 'rxjs/observable/fromPromise'
import { from } from "rxjs";

import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const VERIFY_USER = "dashboard/VERIFY_USER";
export const VERIFY_USER_SUCCESS = "dashboard/VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERROR = "dashboard/VERIFY_USER_ERROR";
export const GET_ALL_DATA = "dashboard/GET_ALL_DATA";
export const UPDATE_SEASON_IMAGE = "dashboard/UPDATE_SEASON_IMAGE";
export const UPDATE_SEASON_IMAGE_SUCCESS =
  "dashboard/UPDATE_SEASON_IMAGE_SUCCESS";
export const UPDATE_SEASON_IMAGE_ERROR = "dashboard/UPDATE_SEASON_IMAGE_ERROR";

export const ADD_FEATURED_TEXT = "dashboard/ADD_FEATURED_TEXT";
export const ADD_FEATURED_TEXT_SUCCESS = "dashboard/ADD_FEATURED_TEXT_SUCCESS";
export const ADD_FEATURED_TEXT_ERROR = "dashboard/ADD_FEATURED_TEXT_ERROR";

export const UPLOAD_IMAGE = "dashboard/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "dashboard/UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "dashboard/UPLOAD_IMAGE_ERROR";

export const UPDATE_SEASON_COVER_PHOTO = "dashboard/UPDATE_SEASON_COVER_PHOTO";
export const UPDATE_SEASON_COVER_PHOTO_SUCCESS =
  "dashboard/UPDATE_SEASON_COVER_PHOTO_SUCCESS";
export const UPDATE_SEASON_COVER_PHOTO_ERROR =
  "dashboard/UPDATE_SEASON_COVER_PHOTO_ERROR";

export const GET_FEATURED_TEXT = "dashboard/GET_FEATURED_TEXT";
export const GET_FEATURED_TEXT_SUCCESS = "dashboard/GET_FEATURED_TEXT_SUCCESS";
export const GET_FEATURED_TEXT_ERROR = "dashboard/GET_FEATURED_TEXT_ERROR";

export const LOGIN = "dashboard/LOGIN";
export const LOGIN_SUCCESS = "dashboard/LOGIN_SUCCESS";
export const LOGIN_ERROR = "dashboard/LOGIN_ERROR";

export const CREATE_SHOW = "dashboard/CREATE_SHOW";

export const HIDE_FEATURE_EPISODES = "dashboard/HIDE_FEATURE_EPISODES";

export const SYNC_SERIES_RECORDS = "dashboard/SYNC_SERIES_RECORDS";   

export const SYNC_MEGAPHONE_SERIES_RECORDS = "dashboard/SYNC_MEGAPHONE_SERIES_RECORDS";   


export const GET_LIST = "dashboard/GET_LIST";   

export const GET_MEGAPHONELIST = "dashboard/GET_MEGAPHONELIST";   

export const ASSIGN_CATEGORIES = "dashboard/ASSIGN_CATEGORIES";

export const PREMIUM_EPISODE = "dashboard/PREMIUM_EPISODE";

export const SEND_GENERAL_NOTIFICATIONS =
  "dashboard/SEND_GENERAL_NOTIFICATIONS";


export const IMPORT_SEASON_EPISODES = "dashboard/IMPORT_SEASON_EPISODES";


export const GET_ALL_SHOWS = "dashboard/GET_ALL_SHOWS";
export const GET_ALL_SHOWS_SUCCESS = "dashboard/GET_ALL_SHOWS_SUCCESS";
export const GET_ALL_SHOWS_ERROR = "dashboard/GET_ALL_SHOWS_ERROR";

export const UPDATE_SEASON_CATEGORIES = "dashboard/UPDATE_SEASON_CATEGORIES";
export const UPDATE_SEASON_CATEGORIES_SUCCESS =
  "dashboard/UPDATE_SEASON_CATEGORIES_SUCCESS";
export const UPDATE_SEASON_CATEGORIES_ERROR =
  "dashboard/UPDATE_SEASON_CATEGORIES_ERROR";

export const EPISODE_SHOW_HIDE = "dashboard/EPISODE_SHOW_HIDE";
export const EPISODE_SHOW_HIDE_SUCCESS = "dashboard/EPISODE_SHOW_HIDE_SUCCESS";
export const EPISODE_SHOW_HIDE_ERROR = "dashboard/EPISODE_SHOW_HIDE_ERROR";

export const UPDATE_ORDER_INDEX = "dashboard/UPDATE_ORDER_INDEX";
export const UPDATE_ORDER_INDEX_SUCCESS =
  "dashboard/UPDATE_ORDER_INDEX_SUCCESS";
export const UPDATE_ORDER_INDEX_ERROR = "dashboard/UPDATE_ORDER_INDEX_ERROR";

export const GET_FEATURED_SHOWS = "dashboard/GET_ALL_FEATURED_SHOWS";
export const GET_FEATURED_SHOWS_SUCCESS =
  "dashboard/GET_ALL_SHOWS_FEATURED_SUCCESS";
export const GET_FEATURED_SHOWS_ERROR =
  "dashboard/GET_ALL_SHOWS_FEATURED_ERROR";

export const GET_SHOW_DETAILS_BY_ID = "dashboard/GET_SHOW_DETAILS_BY_ID";
export const GET_SHOW_DETAILS_BY_ID_SUCCESS =
  "dashboard/GET_SHOW_DETAILS_BY_ID_SUCCESS";
export const GET_SHOW_DETAILS_BY_ID_ERROR =
  "dashboard/GET_SHOW_DETAILS_BY_ID_ERROR";

export const UPDATE_SHOW_DETAILS = "dashboard/UPDATE_SHOW_DETAILS";
export const UPDATE_SHOW_DETAILS_SUCCESS =
  "dashboard/UPDATE_SHOW_DETAILS_SUCCESS";
export const UPDATE_SHOW_DETAILS_ERROR = "dashboard/UPDATE_SHOW_DETAILS_ERROR";

export const UPDATE_EPISODE_DETAILS = "dashboard/UPDATE_EPISODE_DETAILS";
export const UPDATE_FEATURED_DETAILS = "dashboard/UPDATE_FEATURED_DETAILS";
export const GET_USER_DETAILS = "dashboard/GET_USER_DETAILS";
export const UPDATE_USER_DETAILS = "dashboard/UPDATE_USER_DETAILS";

export const GET_ALL_CATEGORIES = "dashboard/GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS =
  "dashboard/GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "dashboard/GET_ALL_CATEGORIES_ERROR";
export const ADD_CATEGORY = "dashboard/ADD_CATEGORY";
export const DELETE_FEATURED_SHOW_DETAILS_BY_ID =
  "dashboard/DELETE_FEATURED_SHOW_DETAILS_BY_ID";

export const DELETE_CATEGORY_DETAILS_BY_ID =
  "dashboard/DELETE_CATEGORY_DETAILS_BY_ID";

export const UPDATE_CATEGORIES_ORDER_INDEX =
  "dashboard/UPDATE_CATEGORIES_ORDER_INDEX";
export const UPDATE_CATEGORIES_ORDER_INDEX_SUCCESS =
  "dashboard/UPDATE_CATEGORIES_ORDER_INDEX_SUCCESS";
export const UPDATE_CATEGORIES_ORDER_INDEX_ERROR =
  "dashboard/UPDATE_CATEGORIES_ORDER_INDEX_ERROR";

export const UPDATE_EPISODE_SEASON_ORDER_INDEX =
  "dashboard/UPDATE_EPISODE_SEASON_ORDER_INDEX";
export const UPDATE_EPISODE_SEASON_ORDER_INDEX_SUCCESS =
  "dashboard/UPDATE_EPISODE_SEASON_ORDER_INDEX_SUCCESS";
export const UPDATE_EPISODE_SEASON_ORDER_INDEX_ERROR =
  "dashboard/UPDATE_EPISODE_SEASON_ORDER_INDEX_ERROR";

export const INIT_LOGIN_PHASE = "dashboard/INIT_LOGIN_PHASE";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  message: "",
  userData: {},
  verifyUserPhase: INIT,
  verifyMessage: "",
  showPhase: INIT,
  uploadImagePhase: INIT,
  uploadImageData: {},
  showData: [],
  showCount: 0,
  featuredShowPhase: INIT,
  featuredShowData: [],
  updateEpisodeDetailsPhase: INIT,
  getShowDetailsByIdData: [],
  getShowDetailsByIdPhase: INIT,
  featuredShowCount: 0,
  updateShowDetailsMessage: "",
  updateShowDetailsPhase: INIT,
  categoryPhase: INIT,
  categoryData: [],
  categoryCount: 0,
  updateSeasonImagePhase: INIT,
  updateSeasonImageData: {},
  updateSeasonCoverPhotoPhase: INIT,
  updateSeasonCoverPhotoData: {},
  addFeaturedTextPhase: INIT,
  addFeaturedTextData: {},

  getFeaturedTextPhase: INIT,
  getFeaturedTextData: {},
  updateCategoriesOrderIndexPhase: INIT,
  updateCategoriesOrderIndexData: {},
  updateOrderIndexPhase: INIT,
  updateOrderIndexData: {},

  updateEpisodeSeasonOrderIndexPhase: INIT,
  updateEpisodeSeasonOrderIndexData: {},

  updateShowHidePhase: INIT,
  updateShowHideData: {},
  createShowPhase: INIT,
  createShowData: {},
  // syncSeriesRecordsPhase: INIT,
  // syncSeriesRecordsData: {},

  hideFeatureEpisodesPhase: INIT,
  hideFeatureEpisodesData: {},
  

  assignCategoriesPhase: INIT,
  assignCategoriesData: {},

  episodePremiumPhase: INIT,
  episodePremiumData: {},


  sendGeneralNotificationsPhase: INIT,
  sendGeneralNotificationsData: {},

  importSeasonEpisodesPhase: INIT,
  importSeasonEpisodesData: {},
  updateSeasonCategoriesPhase: INIT,
  updateSeasonCategoriesData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case LOGIN: {
      return state.set("loginPhase", LOADING).set("error", null);
    }

    case LOGIN_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state.set("loginPhase", SUCCESS).set("message", payload.message);
      } else if (!payload.success) {
        return state.set("loginPhase", ERROR).set("message", payload.message);
      }
      break;
    }

    case LOGIN_ERROR: {
      const { payload } = action;
      return state.set("loginPhase", ERROR).set("error", payload.message);
    }

    case UPLOAD_IMAGE: {
      return state
        .set("uploadImagePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case UPLOAD_IMAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("uploadImagePhase", SUCCESS)
          .set("uploadImageData", payload.data);
      }
    }

    case UPLOAD_IMAGE_ERROR: {
      const { payload } = action;
      return state
        .set("uploadImagePhase", ERROR)
        .set("uploadImageData", {})
        .set("error", payload.message);
    }

    case EPISODE_SHOW_HIDE: {
      return state
        .set("updateShowHidePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case EPISODE_SHOW_HIDE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateShowHidePhase", SUCCESS)
          .set("updateShowHideData", payload.data);
      }
    }

    case EPISODE_SHOW_HIDE_ERROR: {
      const { payload } = action;
      return state
        .set("updateShowHidePhase", ERROR)
        .set("updateShowHideData", {})
        .set("error", payload.message);
    }

    case GET_ALL_CATEGORIES: {
      return state.set("categoryPhase", LOADING).set("error", null);
    }

    case GET_ALL_CATEGORIES_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("categoryPhase", SUCCESS)
          .set("categoryData", payload.data)
          .set("categoryCount", payload.count);
      }
      break;
    }

    case GET_ALL_CATEGORIES_ERROR: {
      return state.set("categoryPhase", ERROR);
    }

    case VERIFY_USER: {
      return state.set("verifyUserPhase", LOADING).set("error", null);
    }

    case VERIFY_USER_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        localStorage.setItem("Authorization", payload?.token);
        return state
          .set("verifyUserPhase", SUCCESS)
          .set("verifyMessage", payload.message);
      } else if (!payload.success) {
        return state
          .set("verifyUserPhase", ERROR)
          .set("verifyMessage", payload.message);
      }
      break;
    }

    case UPDATE_CATEGORIES_ORDER_INDEX: {
      return state
        .set("updateCategoriesOrderIndexPhase", LOADING)
        .set("error", null);
    }

    case UPDATE_CATEGORIES_ORDER_INDEX_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateCategoriesOrderIndexPhase", SUCCESS)
          .set("updateCategoriesOrderIndexData", payload.data);
      }
      break;
    }

    case UPDATE_CATEGORIES_ORDER_INDEX_ERROR: {
      return state.set("updateCategoriesOrderIndexPhase", ERROR);
    }

    case UPDATE_EPISODE_SEASON_ORDER_INDEX: {
      return state
        .set("updateEpisodeSeasonOrderIndexPhase", LOADING)
        .set("error", null);
    }

    case UPDATE_EPISODE_SEASON_ORDER_INDEX_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateEpisodeSeasonOrderIndexPhase", SUCCESS)
          .set("updateEpisodeSeasonOrderIndexData", payload.data);
      }
      break;
    }

    case UPDATE_EPISODE_SEASON_ORDER_INDEX_ERROR: {
      return state.set("updateEpisodeSeasonOrderIndexPhase", ERROR);
    }

    case VERIFY_USER_ERROR: {
      const { payload } = action;
      return state
        .set("verifyUserPhase", ERROR)
        .set("verifyMessage", payload.message);
    }

    case GET_ALL_SHOWS: {
      return state.set("showPhase", LOADING).set("error", null);
    }

    case GET_ALL_SHOWS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("showPhase", SUCCESS)
          .set("showData", payload.data)
          .set("showCount", payload.count);
      }
      break;
    }

    case GET_ALL_SHOWS_ERROR: {
      return state.set("showPhase", ERROR);
    }

  

    case UPDATE_SEASON_CATEGORIES: {
      return state
        .set("updateSeasonCategoriesPhase", LOADING)
        .set("error", null);
    }

    case UPDATE_SEASON_CATEGORIES_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateSeasonCategoriesPhase", SUCCESS)
          .set("updateSeasonCategoriesData", payload.data);
        // .set("showCount", payload.count);
      }
      break;
    }

    case UPDATE_SEASON_CATEGORIES_ERROR: {
      return state.set("updateSeasonCategoriesPhase", ERROR);
    }

    case UPDATE_ORDER_INDEX: {
      return state.set("updateOrderIndexPhase", LOADING).set("error", null);
    }

    case UPDATE_ORDER_INDEX_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateOrderIndexPhase", SUCCESS)
          .set("updateOrderIndexData", payload.data);
      }
      break;
    }

    case UPDATE_ORDER_INDEX_ERROR: {
      return state.set("updateOrderIndexPhase", ERROR);
    }

    case GET_SHOW_DETAILS_BY_ID: {
      return state
        .set("getShowDetailsByIdPhase", LOADING)
        .set("error", null)
        .set("getShowDetailsByIdData", []);
    }

    case GET_SHOW_DETAILS_BY_ID_SUCCESS: {
      const { payload } = action;
      // if(payload.success){
      return state
        .set("getShowDetailsByIdPhase", SUCCESS)
        .set("getShowDetailsByIdData", payload.data);
      // }
      break;
    }

    case GET_SHOW_DETAILS_BY_ID_ERROR: {
      return state.set("getShowDetailsByIdPhase", ERROR);
    }

    case UPDATE_SHOW_DETAILS: {
      return state.set("updateShowDetailsPhase", LOADING).set("error", null);
    }

    case UPDATE_SHOW_DETAILS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateShowDetailsPhase", SUCCESS)
          .set("updateShowDetailsMessage", payload.message);
      }
      break;
    }

    case UPDATE_SHOW_DETAILS_ERROR: {
      return state.set("updateShowDetailsPhase", ERROR);
    }

    case GET_FEATURED_SHOWS: {
      return state.set("featuredShowPhase", LOADING).set("error", null);
    }

    case GET_FEATURED_SHOWS_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("featuredShowPhase", SUCCESS)
          .set("featuredShowData", payload.data)
          .set("featuredShowCount", payload.count);
      }
      break;
    }

    case GET_FEATURED_SHOWS_ERROR: {
      return state.set("featuredShowPhase", ERROR);
    }

    case UPDATE_SEASON_IMAGE: {
      return state
        .set("updateSeasonImagePhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case UPDATE_SEASON_IMAGE_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateSeasonImagePhase", SUCCESS)
          .set("updateSeasonImageData", payload.data);
      }
    }

    case UPDATE_SEASON_IMAGE_ERROR: {
      const { payload } = action;
      return state
        .set("updateSeasonImagePhase", ERROR)
        .set("updateSeasonImageData", {})
        .set("error", payload.message);
    }

    case UPDATE_SEASON_COVER_PHOTO: {
      return state
        .set("updateSeasonCoverPhotoPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case UPDATE_SEASON_COVER_PHOTO_SUCCESS: {
      const { payload } = action;
      if (payload.success) {
        return state
          .set("updateSeasonCoverPhotoPhase", SUCCESS)
          .set("updateSeasonCoverPhotoData", payload.data);
      }
    }

    case UPDATE_SEASON_COVER_PHOTO_ERROR: {
      const { payload } = action;
      return state
        .set("updateSeasonCoverPhotoPhase", ERROR)
        .set("updateSeasonCoverPhotoData", {})
        .set("error", payload.message);
    }

    case ADD_FEATURED_TEXT: {
      return state
        .set("addFeaturedTextPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case ADD_FEATURED_TEXT_SUCCESS: {
      const { payload } = action;
      console.log(payload, "payload");
      if (payload.success) {
        return state
          .set("addFeaturedTextPhase", SUCCESS)
          .set("addFeaturedTextData", payload.data);
      }
    }

    case ADD_FEATURED_TEXT_ERROR: {
      const { payload } = action;
      return state
        .set("addFeaturedTextPhase", ERROR)
        .set("addFeaturedTextData", {})
        .set("error", payload.message);
    }

    case GET_FEATURED_TEXT: {
      return state
        .set("getFeaturedTextPhase", LOADING)
        .set("error", null)
        .set("isSubmitting", true);
    }

    case GET_FEATURED_TEXT_SUCCESS: {
      const { payload } = action;
      console.log(payload, "data>>>>>>>>>>>>");

      if (payload.success) {
        return state
          .set("getFeaturedTextPhase", SUCCESS)
          .set("getFeaturedTextData", payload.data);
      }
    }

    case GET_FEATURED_TEXT_ERROR: {
      const { payload } = action;
      return state
        .set("getFeaturedTextPhase", ERROR)
        .set("getFeaturedTextData", {})
        .set("error", payload.message);
    }

    case INIT_LOGIN_PHASE: {
      return state
        .set("verifyUserPhase", INIT)
        .set("loginPhase", INIT)
        .set("showPhase", INIT)
        .set("categoryPhase", INIT)
        .set("getShowDetailsByIdPhase", INIT)
        .set("updateShowDetailsPhase", INIT)
        .set("featuredShowPhase", INIT)
        .set("updateSeasonImagePhase", INIT)
        .set("updateSeasonCoverPhotoPhase", INIT)
        .set("addFeaturedTextPhase", INIT)
        .set("getFeaturedTextPhase", INIT)
        .set("updateOrderIndexPhase", INIT)
        .set("updateCategoriesOrderIndexPhase", INIT)
        .set("updateEpisodeSeasonOrderIndexPhase", INIT)
        .set("episodeShowHidePhase", INIT)
        .set("updateSeasonCategoriesPhase", INIT);
        
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const loginUser = (value) => {
  return {
    type: LOGIN,
    payload: value,
  };
};

export const addFeaturedText = (value) => {
  return {
    type: ADD_FEATURED_TEXT,
    payload: api.addFeaturedText(value),
  };
};

export const createShow = (value) => {
  return {
    type: CREATE_SHOW,
    payload: api.createShow(value),
  };
};



export const hideFeatureEpisodes = (value) => {
  return {
    type: HIDE_FEATURE_EPISODES,
    payload: api.hideFeatureEpisodes(value),
  };
};

export const syncSeriesRecords = (value) => {
  return {
    type: SYNC_SERIES_RECORDS,
    payload: api.syncSeriesRecords(value),
  };
};

export const syncMegaphoneSeriesRecords = (value) => {
  return {
    type: SYNC_MEGAPHONE_SERIES_RECORDS,
    payload: api.syncMegaphoneSeriesRecords(value),
  };
};  

export const getList = (value) => {
  return {
    type: GET_LIST,
    payload: api.getList(value),
  };
};

export const getMegaphoneList = (value) => {
  return {
    type: GET_MEGAPHONELIST,
    payload: api.getMegaphoneList(value),
  };
};

export const assignCategories = (value) => {
  return {
    type: ASSIGN_CATEGORIES,
    payload: api.assignCategories(value),
  };
};

export const premiumEpisode = (value) => {
  return {
    type: PREMIUM_EPISODE ,
    payload: api.premiumEpisode(value),
  };
};



export const sendGeneralNotifications = (value) => {
  return {
    type: SEND_GENERAL_NOTIFICATIONS,
    payload: api.sendGeneralNotifications(value),
  };
};


export const importSeasonEpisodes = (value) => {
  return {
    type: IMPORT_SEASON_EPISODES,
    payload: api.importSeasonEpisodes(value),
  };
};

export const getFeaturedText = (value) => {
  return {
    type: GET_FEATURED_TEXT,
    payload: api.getFeaturedText(value),
  };
};

export const updateSeasonImage = (value) => {
  return {
    type: UPDATE_SEASON_IMAGE,
    payload: api.updateSeasonImage(value),
  };
};

export const updateSeasonCoverPhoto = (value) => {
  return {
    type: UPDATE_SEASON_COVER_PHOTO,
    payload: api.updateSeasonCoverPhoto(value),
  };
};

export const episodeShowHide = (value) => {
  return {
    type: EPISODE_SHOW_HIDE,
    payload: api.episodeShowHide(value),
  };
};

export const getAllCategories = (value) => {
  return {
    type: GET_ALL_CATEGORIES,
    payload: value,
  };
};

export const getAllShows = (value) => {
  return {
    type: GET_ALL_SHOWS,
    payload: value,
  };
};

export const updateSeasonCategories = (value) => {
  return {
    type: UPDATE_SEASON_CATEGORIES,
    payload: api.updateSeasonCategories(value),
  };
};

export const updateEpisodeDetails = (value) => {
  return {
    type: UPDATE_EPISODE_DETAILS,
    payload: api.updateEpisodeDetails(value),
  };
};

export const getFeaturedShows = (value) => {
  return {
    type: GET_FEATURED_SHOWS,
    payload: value,
  };
};

export const syncShowFromArt = (value) => {
  return {
    type: GET_ALL_SHOWS,
    payload: api.syncShowFromArt(value),
  };
};

export const showList = (value) => {
  return {
    type: GET_ALL_SHOWS,
    payload: api.showList(value),
  };
};

export const featuredShows = (value) => {
  return {
    type: UPDATE_FEATURED_DETAILS,
    payload: api.featureShows(value),
  };
};

export const verifyUser = (value) => {
  return {
    type: VERIFY_USER,
    payload: value,
  };
};

export const getAllData = (value) => {
  return {
    type: GET_ALL_DATA,
    payload: api.getAllData(value),
  };
};

export const getShowDetailsById = (value) => {
  return {
    type: GET_SHOW_DETAILS_BY_ID,
    payload: value,
  };
};

export const deleteFeaturedShows = (value) => {
  return {
    type: DELETE_FEATURED_SHOW_DETAILS_BY_ID,
    payload: api.deleteFeaturedShows(value),
  };
};

export const deleteCategories = (value) => {
  return {
    type: DELETE_CATEGORY_DETAILS_BY_ID,
    payload: api.deleteCategories(value),
  };
};

export const uploadImages = (value) => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImages(value),
  };
};

export const updateCategoriesOrderIndex = (value) => {
  return {
    type: UPDATE_CATEGORIES_ORDER_INDEX,
    payload: api.updateCategoriesOrderIndex(value),
  };
};

export const updateEpisodeSeasonOrderIndex = (value) => {
  return {
    type: UPDATE_EPISODE_SEASON_ORDER_INDEX,
    payload: api.updateEpisodeSeasonOrderIndex(value),
  };
};

// export const uploadImages = credentials => {
//   return {
//     type: UPLOAD_IMAGE,
//     payload: credentials
//   }
// }

export const updateShowDetails = (value) => {
  return {
    type: UPDATE_SHOW_DETAILS,
    payload: value,
  };
};

export const resetLoginPhase = (value) => {
  return {
    type: INIT_LOGIN_PHASE,
    payload: value,
  };
};

export const getUserDetails = (value) => {
  return {
    type: GET_USER_DETAILS,
    payload: api.getUserDetails(value),
  };
};

export const updateUser = (value) => {
  return {
    type: UPDATE_USER_DETAILS,
    payload: api.updateUser(value),
  };
};

export const addCategory = (value) => {
  return {
    type: ADD_CATEGORY,
    payload: api.addCategory(value),
  };
};

export const updateOrderIndex = (value) => {
  return {
    type: UPDATE_ORDER_INDEX,
    payload: api.updateOrderIndex(value),
  };
};

/***********************************
 * Epics
 ***********************************/

const updateCategoriesOrderIndexEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_CATEGORIES_ORDER_INDEX),
    mergeMap((action) => {
      return from(api.updateCategoriesOrderIndex(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_CATEGORIES_ORDER_INDEX_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_CATEGORIES_ORDER_INDEX_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const loginEpic = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    mergeMap((action) => {
      return from(api.login(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: LOGIN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: LOGIN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const verifyUserEpic = (action$) =>
  action$.pipe(
    ofType(VERIFY_USER),
    mergeMap((action) => {
      return from(api.verifyUser(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: VERIFY_USER_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: VERIFY_USER_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const showsEpic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_SHOWS),
    mergeMap((action) => {
      return from(api.shows(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ALL_SHOWS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ALL_SHOWS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );


const episodeShowHideEpic = (action$) =>
  action$.pipe(
    ofType(EPISODE_SHOW_HIDE),
    mergeMap((action) => {
      return from(api.episodeShowHide(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: EPISODE_SHOW_HIDE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: EPISODE_SHOW_HIDE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const featuredShowsEpic = (action$) =>
  action$.pipe(
    ofType(GET_FEATURED_SHOWS),
    mergeMap((action) => {
      return from(api.featuredShows(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_FEATURED_SHOWS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_FEATURED_SHOWS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateSeasonCategoriesEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SEASON_CATEGORIES),
    mergeMap((action) => {
      return from(api.updateSeasonCategories(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_SEASON_CATEGORIES_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_SEASON_CATEGORIES_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getShowsDetailsByIdEpic = (action$) =>
  action$.pipe(
    ofType(GET_SHOW_DETAILS_BY_ID),
    mergeMap((action) => {
      return from(api.getShowsDetailsById(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_SHOW_DETAILS_BY_ID_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_SHOW_DETAILS_BY_ID_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateShowDetailsEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SHOW_DETAILS),
    mergeMap((action) => {
      return from(api.updateShowDetails(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_SHOW_DETAILS_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_SHOW_DETAILS_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const categoriesEpic = (action$) =>
  action$.pipe(
    ofType(GET_ALL_CATEGORIES),
    mergeMap((action) => {
      return from(api.categories(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_ALL_CATEGORIES_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_ALL_CATEGORIES_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const uploadImagesEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_IMAGE),
    mergeMap((action) => {
      return from(api.uploadImages(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPLOAD_IMAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPLOAD_IMAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );
const updateSeasonImageEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SEASON_IMAGE),
    mergeMap((action) => {
      return from(api.updateSeasonImage(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_SEASON_IMAGE_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_SEASON_IMAGE_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateSeasonCoverPhotoEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_SEASON_COVER_PHOTO),
    mergeMap((action) => {
      return from(api.updateSeasonCoverPhoto(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_SEASON_COVER_PHOTO_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_SEASON_COVER_PHOTO_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const addFeaturedTextEpic = (action$) =>
  action$.pipe(
    ofType(ADD_FEATURED_TEXT),
    mergeMap((action) => {
      return from(api.addFeaturedText(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: ADD_FEATURED_TEXT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: ADD_FEATURED_TEXT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const getFeaturedTextEpic = (action$) =>
  action$.pipe(
    ofType(GET_FEATURED_TEXT),
    mergeMap((action) => {
      return from(api.getFeaturedText(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: GET_FEATURED_TEXT_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: GET_FEATURED_TEXT_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateEpisodeSeasonOrderIndexEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_EPISODE_SEASON_ORDER_INDEX),
    mergeMap((action) => {
      return from(api.updateEpisodeSeasonOrderIndex(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_EPISODE_SEASON_ORDER_INDEX_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_EPISODE_SEASON_ORDER_INDEX_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

const updateOrderIndexEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_ORDER_INDEX),
    mergeMap((action) => {
      return from(api.updateOrderIndex(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: UPDATE_ORDER_INDEX_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: UPDATE_ORDER_INDEX_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const dashboardEpic = combineEpics(
  loginEpic,
  verifyUserEpic,
  showsEpic,
  featuredShowsEpic,
  getShowsDetailsByIdEpic,
  updateShowDetailsEpic,
  categoriesEpic,
  uploadImagesEpic,
  updateSeasonImageEpic,
  updateSeasonCoverPhotoEpic,
  addFeaturedTextEpic,
  getFeaturedTextEpic,
  updateOrderIndexEpic,
  updateCategoriesOrderIndexEpic,
  updateEpisodeSeasonOrderIndexEpic,
  episodeShowHideEpic,
  updateSeasonCategoriesEpic,
);
