import * as React from "react"

const Info = ({ ...props }) => {
  return (
    // <svg
    //   width={16}
    //   height={16}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16ZM7 8.089V8.5a1 1 0 0 0 1.926.377 3.5 3.5 0 1 0-3.401-5.852A3.437 3.437 0 0 0 4.5 5.51a1.005 1.005 0 1 0 2.009-.02 1.43 1.43 0 0 1 .436-1.045 1.5 1.5 0 0 1 1.058-.437 1.492 1.492 0 0 1 0 2.983A1.004 1.004 0 0 0 7 8.09v-.001ZM8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    //     fill="#A3ACB9"
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" {...props}>
      <path d="M8.5.835A7.67 7.67 0 0116.165 8.5 7.67 7.67 0 018.5 16.165 7.67 7.67 0 01.835 8.5 7.67 7.67 0 018.5.835zM8.5 0C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zm0 12.55a.606.606 0 01-.606-.606V7.978a.607.607 0 011.212 0v4c0 .313-.27.585-.606.585zm0-6.9a.607.607 0 11.002-1.214A.607.607 0 018.5 5.65z"></path>
    </svg>
  );
};



export default Info
