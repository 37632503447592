import React, { useEffect, useState } from "react";
import GeneralComponent from "./General/component";
import UploadSeasonOrEpisodeComponent from "./Upload Season or Episodes/component";
import EpisodesSeasons from "./EpisodesSeasons/component";
import Close from "../../components/icons/Close";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { get } from "lodash";

// import Styles from "./showDetails.module.css"

export default function ShowDetailsComponent(props) {
  const [data, setdata] = useState({});
  const [tab, setTab] = useState("show-details");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  // useEffect(() => {
  //   const { pathname } = location;
  //   if (pathname === "/show-details/:id") {
  //     setTab("list-shows");
  //     navigate("/show-details/:id", { state: {} });
  //   } else if (pathname === "/episode-season/:id") {
  //     setTab("episode-season");
  //     navigate("/episode-season/:id", { state: {} });
  //   } else {
  //     setTab("categories");
  //     navigate("/categories", { state: {} });
  //   }
  // }, [tab]);

  useEffect(() => {
    const { pathname, state } = location;
    if (pathname.includes("show-details")) {
      setTab("show-details");
      navigate(`/show-details/${get(state, "data._id")}`, { state: state });
    } else if (pathname.includes("episode-season")) {
      setTab("episode-season");
      navigate(`/episode-season/${get(state, "data._id")}`, { state: state });
    } else if (pathname.includes("/upload-season-or-episodes")) {
      setTab("upload-season-or-episodes");
      navigate(`/upload-season-or-episodes/${get(state, "data._id")}`, {
        state: state,
      });
    }
  }, [tab]);

  const onTabChange = (tab) => {
    const { state } = location;
    setTab(tab);
    if (tab === "show-details") {
      navigate(`/show-details/${get(state, "data._id")}`, { state: state });
    } else if (tab === "episode-season") {
      navigate(`/episode-season/${get(state, "data._id")}`, { state: state });
    } else if (tab === "upload-season-or-episodes") {
      navigate(`/upload-season-or-episodes/${get(state, "data._id")}`, {
        state: state,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getAllCategories({ pageNumber: 1, pageSize: 20 });
    props.getShowDetailsById({ id: params.id });
  }, []);

  useEffect(() => {
    const { state } = location;
    // console.log(props,'props',params)
    if (get(props, "getShowDetailsByIdPhase", "") === "success") {
      props.resetLoginPhase();
      setdata(get(props, "getShowDetailsByIdData", {})[0]);
    }
    setdata(get(state, "data", {}));
  }, [data, props?.getShowDetailsByIdPhase]);

  return (
    <>
      <main className="main" role="head">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => {navigate(`/shows`,{
                        state: { pageNumber: location?.state?.pageNumber },
                      })
                    }}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">{data.title}</div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="tabs__links tabs__links--space">
          <button
            onClick={() => onTabChange("show-details")}
            className={
              tab === "show-details" ? "tabs__link active" : "tabs__link"
            }
          >
            General
          </button>
          <button
            onClick={() => onTabChange("episode-season")}
            className={
              tab === "episode-season" ? "tabs__link active" : "tabs__link"
            }
          >
            Episodes/Seasons
          </button>
          <button
            onClick={() => onTabChange("upload-season-or-episodes")}
            className={
              tab === "upload-season-or-episodes"
                ? "tabs__link active"
                : "tabs__link"
            }
          >
            Upload Season or Episodes
          </button>
        </div>
        {tab === "show-details" ? (
          <GeneralComponent data={data} props={props} />
        ) : tab === "episode-season" ? (
          <EpisodesSeasons data={data} props={props} />
        ) : (
          <UploadSeasonOrEpisodeComponent data={data} props={props} />
        )}
      </main>
    </>
  );
}
