import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
// import DocumentMeta from 'react-document-meta';

export default function Deeplink(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (document) {
      let frame = document;
      const android = /(android)/i.test(navigator.userAgent);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const id =
        document?.location?.pathname?.split("/")[
          document?.location?.pathname?.split("/").length - 1
        ];
      const showId =
        document?.location?.pathname?.split("/")[
          document?.location?.pathname?.split("/").length - 2
        ];

      setTimeout(() => {
        if (android) {
          //   frame.location = process.env.REACT_APP_ANDROID_URL;
          frame.location =
            "https://play.google.com/store/apps/details?id=com.genz";
        } else if (iOS) {
          //   frame.location = process.env.REACT_APP_IOS_URL;
          frame.location =
            "https://apps.apple.com/in/app/gzm-shows/id1631808572";
        }
      }, 5000);
      if (showId === "shows") {
        frame.location = `gzmshows://show_${id}`;
      } else {
        frame.location = `gzmshows://episode_${id}`;
      }
    }
  }, []);
  //   const meta = {
  //     title: 'Some Meta Title',
  //     description: 'I am a description, and I can create multiple tags',
  //     canonical: 'http://example.com/path/to/page',
  //     meta: {
  //       charset: 'utf-8',
  //       name: {
  //         keywords: 'react,meta,document,html,tags'
  //       }
  //     }
  //   };
  return (
    <div>
      {/*<MetaTags>
            <meta id="meta-description" name="description" content="Some description." />
            <meta id="og-title" perty="og:titlerop" content="MyApp" />
            <meta id="og-image" property="og:image" content="path/to/image.jpg" />
          </MetaTags>*/}
      {/*<Helmet>
            <meta charSet="utf-8" />
            <title>Gen-Z-Media</title>
            <meta name="description" content={searchParams.get("description")} />
            <meta name="title" content={searchParams.get("title")} />
            <meta name="image" content={searchParams.get("image")} />
            <meta name="og:description" content={searchParams.get("description")}/>
            <meta name="og:title" content={searchParams.get("title")}/>
            <meta name="og:image" content={searchParams.get("image")}/>
        </Helmet>*/}
      {/*<DocumentMeta {...meta}>
        <h1> Loading...</h1>
      </DocumentMeta> */}
      Loading...
    </div>
  );
}
