import React, { useState, useEffect } from "react";
import { Close } from "../../../components/icons";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { get } from "lodash";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function NewShowFromArt19Component(props) {
  const [list, setList] = useState(null);
  const [loaderUser, setLoaderUser] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.getMegaphoneList({
          // pageNumber: currentPage,
          pageSize: resultsPerPage,
        });
        console.log("_----------------", response);
        const data = response.value.result || [];
        // console.log("API Response Data:", data);
        setList(data);
        setTotalPages(Math.ceil(data.length / resultsPerPage));
        setLoaderUser(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setList([]);
        setLoaderUser(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const syncMegaphoneSeriesRecords = async (id) => {
    console.log("id", id);
    setLoaderUser(true);
    const value = await props.syncMegaphoneSeriesRecords({ id: id });

    if (value?.value?.success === "true") {
      console.log("API Response222222222222244:", value?.value?.success);

      const response = await props.getMegaphoneList({
        pageNumber: currentPage,
        pageSize: resultsPerPage,
      });
      console.log("response insi   if-----", response);

      const data = response.value.result || [];
      console.log("=================>", data);
      setList(data);
      setTotalPages(Math.ceil(data.length / resultsPerPage));
      setLoaderUser(false);
      // setLoaderUser(false);
      openNotificationWithIcon(
        "success",
        "Sync series successfully, Episodes media play time is updating you can activate this shows after 5 mins"
      );
    } else {
      setLoaderUser(false);
      openNotificationWithIcon(
        "error",
        "Syncing failed(Not found appropriate data in Megaphone)."
      );
    }
  };

  const onSave = () => {
    openNotificationWithIcon("success", "Saved changes successfully.");
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <div className="custom__container">
        <div className="main__header">
          <header class="head">
            <div class="head__container">
              <div class="head__row">
                <div class="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/shows")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div class="head__divider"></div>
                  <div class="head__title">Megaphone Shows</div>
                </div>
                {/* <div className="head__right">
                  <button
                    type="button"
                    className="btn btn__green"
                    onClick={() => {
                      navigate("/shows");
                      onSave();
                    }}
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </div>
          </header>
        </div>

        <div className="container-small small-table-block">
          <div className="form-group"></div>
          <div className="custom__container">
            <div className="table-responsive table__responsive--custom">
              {loaderUser ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                <table className="table table__custom drag-table-container">
                  <thead>
                    <tr>
                      <th className="title-block">ALL MEGAPHONE SHOWS</th>
                      <th className="status">STATUS</th>
                      <th className="action-description text-center">
                        SYNC TO DATABASE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.length > 0 &&
                      list
                        .slice(
                          (currentPage - 1) * resultsPerPage,
                          currentPage * resultsPerPage
                        )
                        .map((l, i) => (
                          <tr key={i}>
                            <td style={{ whiteSpace: "normal" }}>{l.title}</td>
                            {l?.status === "Not Synced" ? (
                              <td
                                className="table__status not-active"
                                style={{ color: "#FF69B4" }}
                              >
                                {l.status}
                              </td>
                            ) : (
                              <td
                                className="table__status active"
                                style={{ color: "#5ABDCE" }}
                              >
                                {l.status}
                              </td>
                            )}
                            <td className="text-center">
                              {l?.status === "Not Synced" && (
                                <button
                                  style={{ color: "#5ABDCE" }}
                                  className="sync-btn"
                                  onClick={() =>
                                    syncMegaphoneSeriesRecords(
                                      l?.seriesObjectImported.id
                                    )
                                  }
                                >
                                  <SyncOutlined />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              )}
              {/* {list === null && (
                <div>Loading...</div>
              )} */}

              <div className="table__pagination">
                <div className="table__pagination--results">
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
                <div className="table__pagination--nav">
                  <>
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Previous Page
                    </button>
                  </>
                  <>
                    <button
                      className="btn btn__default table__pagination--prev"
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next Page
                    </button>
                  </>
                </div>
              </div>

              {list && list.length === 0 && (
                <div className="table__blank">
                  <div className="table__blank--text">No Data</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
