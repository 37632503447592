import React, { useState, useEffect, useRef } from "react";
import { Remove, Plus, Close } from "../../../components/icons";
import { Select } from "antd";
import { get, isEmpty } from "lodash";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Spin, notification } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import CSVReader from 'react-csv-reader'
import { CSVLink } from "react-csv";
const { Option } = Select;
let children = [];


const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function UploadSeasonOrEpisodeComponent({props}) {
//  console.log(props, "props===============>");
  const [coverPreviewArr, setCoverPreviewArr] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [coverLoader, setCoverLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [initialState, setInitialState] = useState({});
  const [data, setData] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadLoading, setUploadLoading] = useState();
  const [cancel, setCancel] = useState(false);

  const ref = useRef();

  const fileHandler = async(records) => {
    setData(records)
    setCancel(true);
  }
  

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
      header
        .toLowerCase()
        .replace(/\W/g, '_')
  }

  const isCancel = () => {
  
    setCancel(false);
    setData([])
  };

 
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  useEffect(() => {}, [
    coverPreviewArr,
    imageError,
    showLoader,
    showPreviewArr,
    seasons,
    cancel,
    data
  ]);

  const bytesToSize = (bytes) => bytes / (2048 * 2048);

  const uploadSeasonOrUpisode = async (e) => { 
    console.log("enter")
    e.preventDefault()
    let url = window.location.pathname;
    let seriesId = url.split("/").pop();
    // let seriesId = hh.split("/");

    if (data.length === 0) {
      openNotificationWithIcon("error", "Please upload the Excel file first.");
      return;
    }
  
    
    const { value } = await props.importSeasonEpisodes({ seriesId: seriesId, data : data})
    // if (value.success) {
    //   props.resetLoginPhase();
    //   await props.getShowDetailsById({
    //     id: get(props, "getShowDetailsByIdData", [])[0]?._id,
    //   });
    openNotificationWithIcon("success", "Upload Season or Episodes Excel successfully.");
    
  };

  const headers = [
    { label: "Season Number", key: "seasonNo" },
    { label: "Episode Number", key: "episodeNo" },
    { label: "Episode Title", key: "episodeTitle" },
    { label: "Episode Short Description", key: "episodeName" },
    { label: "Episode Description", key: "episodeDesc" },
    { label: "Ad Audio", key: "adAudio" },
    { label: "Ad Free Audio", key: "adFreeAudio" },
  ];

  const Episodedata = [
    {
      "seasonNo": "1",
      "episodeNo": "1",
      "episodeTitle": "Episode 1",
      "episodeName": "Episode 1",
      "episodeDesc": "In which, I, Brynleigh A. Pasternack, demonstrate my talents as a podcastress.",
      "adAudio": "https://drive.google.com/file/d/18fzABAcbvJX18gW609ORv3sZr8mXCVFG/view?usp=share_link",
      "adFreeAudio": "https://drive.google.com/file/d/18fzABAcbvJX18gW609ORv3sZr8mXCVFG/view?usp=share_link"
    },
    {
      "seasonNo": "1",
      "episodeNo": "2",
      "episodeTitle": "Episode 2",
      "episodeName": "Episode 2",
      "episodeDesc": "Cyrus and I answer emails from you the audience…who, frankly, could be more thoughtful with your questions.",
      "adAudio": "https://drive.google.com/file/d/1BD3TiCo_ZXvS2XBdrdXkvNqoUjtUxdUG/view?usp=share_link",
      "adFreeAudio": "https://drive.google.com/file/d/1BD3TiCo_ZXvS2XBdrdXkvNqoUjtUxdUG/view?usp=share_link"
    },
    {
      "seasonNo": "1",
      "episodeNo": "3",
      "episodeTitle": "Episode 3",
      "episodeName": "Episode 3",
      "episodeDesc": "When you’re a celeb like me, sometimes you have to deal with weirdos.",
      "adAudio": "https://drive.google.com/file/d/1PBSygpU-olhn9eayFQ-U5JUYk_cVfNpA/view?usp=share_link",
      "adFreeAudio": "https://drive.google.com/file/d/1PBSygpU-olhn9eayFQ-U5JUYk_cVfNpA/view?usp=share_link"
    },
    {
      "seasonNo": "1",
      "episodeNo": "4",
      "episodeTitle": "Episode 4",
      "episodeName": "Episode 4",
      "episodeDesc": "After a long hiatus, I triumphantly return to record a podcast…from the bathroom of a commercial plane en route to Florida. I’m joined by my recording engineer (and definitely not sidekick) Cybot.",
      "adAudio": "https://drive.google.com/file/d/1Kc78FvpLQcsFuKrG4XMU9ATor45FnhSL/view?usp=share_link",
      "adFreeAudio": "https://drive.google.com/file/d/1Kc78FvpLQcsFuKrG4XMU9ATor45FnhSL/view?usp=share_link"
    },
    {
      "seasonNo": "1",
      "episodeNo": "5",
      "episodeTitle": "Episode 5",
      "episodeName": "Episode 5",
      "episodeDesc": "I settle in my new home at the exclusive Elixir Academy, which is definitely not creepy and mysterious at all.",
      "adAudio": "https://drive.google.com/file/d/1CeIR4RLky72C23rU6ISLcYFwuMIFxOBQ/view?usp=share_link",
      "adFreeAudio": "https://drive.google.com/file/d/1CeIR4RLky72C23rU6ISLcYFwuMIFxOBQ/view?usp=share_link"
    },
  ];
  
  return (
    <>
      <div className="custom__container">
        <div className="custom__wrap">
          <div className="form-group">
               {/* <form onSubmit={this.uploadLeads} noValidate> */}
               <form ref={ref} noValidate>
              <div className="form-group">
                {/* <form ref={ref}></form> */}
                <div className="d-flex align-item-center justify-content-between mb-2">
                <label className="label-text mb-2">Upload Season or Episodes Excel</label>
                  
                  <CSVLink
                    data={Episodedata}
                    headers={headers}
                    filename="Gen-Z_ Upload Season/Episodes.csv"
                    className="btn btn__green"
                    // className="btn btn-primary"
                    target={"_blank"}
                  >
                    Download Template
                  </CSVLink>
                </div>
                  <div className="dropzone-file">
                    <button
                      type="button"
                      className="btn btn__green"
                    >
                      <Plus className="btn__icon" />
                      Upload Excel
                    </button>
                   
                    <CSVReader
                      cssClass="csv-reader-uploader"
                      // onFileLoaded={fileHandler}
                      
                      onFileLoaded={(data) => {
                        fileHandler(data);
                        ref.current.reset();
                      
                      }}
                      onError={fileHandler}
                      parserOptions={papaparseOptions}
                      inputId="ObiWan"
                      // inputStyle={{color: 'red'}}
                    />
                  </div>
                 
                  
                  {cancel === true &&
                  <div className="upload-content">

                    <button className="remove-csv-file" onClick={()=>isCancel()}><CloseOutlined/></button>
                    <img src="https://www.spreadsheetweb.com/wp-content/uploads/2021/01/How-to-avoid-formatting-change-on-CSV-files-in-Excel-00.png" />
                  </div>
                  }
                 {uploadError && <div className="invalid-feedback">{errorMessage}</div>}
              </div>
              {/* <button className="btn btn-dark btn-lg w-100" type='submit' disabled={mailLoading}> */}
              <button onClick={(e)=>uploadSeasonOrUpisode(e)} className="btn btn__green w-100" type='submit'>
                {uploadLoading ?
                  <Spin size="small" />             
                  :
                  "Add Season or Episodes"
                }
              </button>

              </form>     
          
    
          </div>
          {/* <button
            // onClick={() => updateShowDetails()}
            type="submit"
            className="btn btn-lg btn__green w-100"
          >
           Add Shows or Episodes
          </button> */}
        </div>
      </div>
    </>
  );
}
// }