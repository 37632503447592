import { fetch } from "../../utils";
// import { get } from 'lodash'
const HostName = process.env.REACT_APP_API_HOSTNAME;

export const login = (value) => {
  return fetch(`${HostName}/api/v1/user/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyUser = (value) => {
  return fetch(`${HostName}/api/v1/user/verify-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const syncShowFromArt = (value) => {
  return fetch(`${HostName}/api/v1/admin/sync-series`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const shows = (value) => {
  const url = new URL(`${HostName}/api/v1/admin/get-all-series`);

  // Append the query parameters to the URL
  url.searchParams.append("pageNumber", value.pageNumber);
  url.searchParams.append("pageSize", value.pageSize);
  if (value.isMegaPhone) {
    console.log("isMegaPhone==================>", value.isMegaPhone);
    url.searchParams.append("isMegaPhone", value.isMegaPhone);
  }

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const featuredShows = (value) => {
  return fetch(
    `${HostName}/api/v1/admin/get-featured-shows?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getShowsDetailsById = (value) => {
  return fetch(`${HostName}/api/v1/admin/get-series-by-id?id=${value.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateShowDetails = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-show-details`, {
    method: "POSt",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEpisodeDetails = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-episode`, {
    method: "POSt",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const showList = (value) => {
  return fetch(`${HostName}/api/v1/admin/showList`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(value)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const featureShows = (value) => {
  return fetch(`${HostName}/api/v1/admin/featuredShows`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserDetails = (value) => {
  return fetch(`${HostName}/api/v1/user/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(value)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllData = (value) => {
  return fetch(`${HostName}/api/v1/admin/export-data`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(value)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateUser = (value) => {
  return fetch(`${HostName}/api/v1/admin/updateUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const categories = (value) => {
  return fetch(
    `${HostName}/api/v1/admin/get-all-categories?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addCategory = (value) => {
  return fetch(`${HostName}/api/v1/admin/add-categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteFeaturedShows = (value) => {
  return fetch(`${HostName}/api/v1/admin/deleteFeaturedShows`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadImages = (value) => {
  return fetch(`${HostName}/api/v1/admin/uploadImage`, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'image/jpeg',
    // },
    // mode: 'cors',
    body: value,
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSeasonImage = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-season-image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const addFeaturedText = (value) => {
  return fetch(`${HostName}/api/v1/admin/add-featured-text`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFeaturedText = (value) => {
  return fetch(`${HostName}/api/v1/admin/get-featured-text`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteCategories = (value) => {
  return fetch(`${HostName}/api/v1/admin//deleteCategories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateOrderIndex = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-order-index`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const updateCategoriesOrderIndex = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-categories-order-index`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEpisodeSeasonOrderIndex = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-episodes-order-index`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const episodeShowHide = (value) => {
  return fetch(`${HostName}/api/v1/admin/episodes-show-hide`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const createShow = (value) => {
  return fetch(`${HostName}/api/v1/admin/create-show`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSeasonCoverPhoto = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-season-cover-photo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSeasonCategories = (value) => {
  return fetch(`${HostName}/api/v1/admin/update-season-categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const importSeasonEpisodes = (value) => {
  return fetch(`${HostName}/api/v1/admin/import-season-episodes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendGeneralNotifications = (value) => {
  return fetch(`${HostName}/api/v1/admin/send-Notifications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const assignCategories = (value) => {
  return fetch(`${HostName}/api/v1/admin/assign-categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getList = (value) => {
  return fetch(
    `${HostName}/api/v1/admin/get-list?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}&text=${value?.text}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMegaphoneList = (value) => {
  return fetch(
    `${HostName}/api/v1/admin/getMegaphoneList?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const syncMegaphoneSeriesRecords = (value) => {
  return fetch(`${HostName}/api/v1/admin/syncMegaphoneSeriesRecords`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const syncSeriesRecords = (value) => {
  return fetch(`${HostName}/api/v1/admin/sync-series-records`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const premiumEpisode = (value) => {
  return fetch(`${HostName}/api/v1/admin/premium-episode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const hideFeatureEpisodes = (value) => {
  return fetch(`${HostName}/api/v1/admin/hide-featured-show`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
