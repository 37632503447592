import { connect } from "react-redux";
import ShowDetailsComponent from "./component";
import {
  getShowDetailsById,
  uploadImages,
  getAllCategories,
  episodeShowHide,
  updateShowDetails,
  updateEpisodeDetails,
  resetLoginPhase,
  updateSeasonImage,
  updateEpisodeSeasonOrderIndex,
  updateSeasonCoverPhoto,
  updateSeasonCategories,
  importSeasonEpisodes,
  premiumEpisode,
} from "../../store/dashboard/duck";

const ShowDetailsContainer = connect(
  // Map state to props
  (state) => ({
    updateEpisodeDetailsPhase: state.dashboard.updateEpisodeDetailsPhase,
    getShowDetailsByIdPhase: state.dashboard.getShowDetailsByIdPhase,
    getShowDetailsByIdData: state.dashboard.getShowDetailsByIdData,
    updateShowDetailsPhase: state.dashboard.updateShowDetailsPhase,
    updateShowDetailsMessage: state.dashboard.updateShowDetailsMessage,
    categoryCount: state.dashboard.categoryCount,
    categoryData: state.dashboard.categoryData,
    categoryPhase: state.dashboard.categoryPhase,
    updateSeasonImageData: state.dashboard.updateSeasonImageData,
    updateSeasonImagePhase: state.dashboard.updateSeasonImagePhase,
    updateEpisodeSeasonOrderIndexData:
    state.dashboard.updateEpisodeSeasonOrderIndexData,
    updateEpisodeSeasonOrderIndexPhase:
    state.dashboard.updateEpisodeSeasonOrderIndexPhase,
    updateSeasonCoverPhotoData: state.dashboard.updateSeasonCoverPhotoData,
    updateSeasonCoverPhotoPhase: state.dashboard.updateSeasonCoverPhotoPhase,
    updateSeasonCategoriesData: state.dashboard.updateSeasonCategoriesData,
    updateSeasonCategoriesPhase: state.dashboard.updateSeasonCategoriesPhase,
    premiumEpisodeData: state.dashboard.premiumEpisodeData,
    premiumEpisodePhase: state.dashboard.premiumEpisodePhase,
  }),
  // Map actions to dispatch and props
  {
    uploadImages,
    updateSeasonImage,
    getAllCategories,
    updateEpisodeDetails,
    resetLoginPhase,
    getShowDetailsById,
    episodeShowHide,
    updateShowDetails,
    updateEpisodeSeasonOrderIndex,
    updateSeasonCoverPhoto,
    updateSeasonCategories,
    importSeasonEpisodes,
    premiumEpisode,
  }
)(ShowDetailsComponent);

export default ShowDetailsContainer;
