import { connect } from "react-redux";
import SendPushNotificationsComponent from "./PushSendNotification/component";
import {
sendGeneralNotifications
  
} from "../../store/dashboard/duck";

const UploadSeasonOrEpisodeContainer = connect(
  // Map state to props
  (state) => ({
    updateEpisodeDetailsPhase: state.dashboard.updateEpisodeDetailsPhase,
    getShowDetailsByIdPhase: state.dashboard.getShowDetailsByIdPhase,
    getShowDetailsByIdData: state.dashboard.getShowDetailsByIdData,
    updateShowDetailsPhase: state.dashboard.updateShowDetailsPhase,
    updateShowDetailsMessage: state.dashboard.updateShowDetailsMessage,
    categoryCount: state.dashboard.categoryCount,
    categoryData: state.dashboard.categoryData,
    categoryPhase: state.dashboard.categoryPhase,
    updateSeasonImageData: state.dashboard.updateSeasonImageData,
    updateSeasonImagePhase: state.dashboard.updateSeasonImagePhase,
    updateEpisodeSeasonOrderIndexData: state.dashboard.updateEpisodeSeasonOrderIndexData,
    updateEpisodeSeasonOrderIndexPhase: state.dashboard.updateEpisodeSeasonOrderIndexPhase,
    sendGeneralNotificationsData: state.dashboard.sendGeneralNotificationsData,
    sendGeneralNotificationsPhase: state.dashboard.sendGeneralNotificationsPhase,
  }),
  // Map actions to dispatch and props
  {
    sendGeneralNotifications,
  }
)(SendPushNotificationsComponent);

export default UploadSeasonOrEpisodeContainer;