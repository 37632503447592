import React, { useEffect, useState, useReducer } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { get, size, isEmpty } from "lodash";
import ActionMoreComponent from "../../../components/common/ActionMore/component";
// import TablePaginationComponent from "../../../components/common/TablePagination/component"
import { Edit, Close, Eye, Plus, Setting } from "../../../components/icons";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.scss";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Remove } from "../../../components/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function AppHomeScreenComponent(props) {

  const initial_page_state = {
    is_select_show_error: false,
    is_featured_description_error: false,
    is_image_error: false,
    is_select_episode: false,
    is_select_season_error: false,
    is_feed_url_error: false
  }
  const [page_state, set_page_state] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initial_page_state)



  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsShowOpen, setIsShowOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({
    description: ""
  });
  const [showsListData, setShowsListData] = useState([]);
  const [showPreviewArr, setShowPreviewArr] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [opentextModal, setOpenTextModal] = useState(false);
  const [seasonsData, setSeasonsData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Feature by Show");
  const [initialState, setInitialState] = useState({});
  const [image, setImage] = useState("");
  const [imageTrue, setImageTrue] = useState(true);

  const onSelectedStatus = (key, value) => {
    if (
      key === "type" &&
      (value === "Feature by Show" ||
        value === "Feature an Episode" ||
        value === "Feature by URL")
    ) {
      setSelectedStatus(value);

      initialState["type"] =
        value === "Feature an Episode"
          ? "Feature an Episode"
          : value === "Feature by Show"
          ? "Feature by Show"
          : "Feature by URL";
    } else {
      setSelectedStatus(value);
    }
    setInitialState(initialState);
  };

  const handleTextChange = (name, value) => {
    selectedData["feedUrl"] = value;
    setSelectedData({ ...selectedData });
  };

  const activeStatus = [
    { key: "Feature by Show", value: "Feature by Show" },
    { key: "Feature an Episode", value: "Feature an Episode" },
    { key: "Feature by URL", value: "Feature by URL" },
  ];

  // const [hideModal, setHideModal] = useState(false);

  const  openModal = async (data) =>{
    setIsOpen(true);
  }

  const openModalForText = () => {
    setOpenTextModal(true);
  };

  function openIsModal() {
    setIsShowOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setDeleteOpenModal(false);
    setIsShowOpen(false);
    setOpenTextModal(false);
    set_page_state(initial_page_state)
    setImage("")
    console.log("work modal close")
  }

  const [showsList, setShowsList] = useState([]);
  const [secondStep, setSecondStep] = useState(0);
  const [firstStep, setFirstStep] = useState(1);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [textError, setTextError] = useState("");
  const [featuredData, setFeaturedData] = useState([]);
  const [text, setText] = useState("");
  const [addFeaturedData, setAddFeaturedData] = useState([]);
  const openModalDelete = (data) => {
    setSelectedData(data);
    setDeleteOpenModal(true);
  };

  

  useEffect(() => {
    props.getFeaturedShows({ pageNumber: 1, pageSize: 20 });
    const callApi = async () => {
      const { value } = await props.showList();
      setLoader(false);
      setShowsListData(get(value, "data", []));
      const res = await props.getFeaturedText({});
      if (res.value.success) {
        setFeaturedData(res.value.data);
        setText(res.value.data.text);
      }
    };
    callApi();
  }, []);

  useEffect(() => {
    if (get(props, "featuredShowPhase", "") === "success") {
      setShowsList(props.featuredShowData);
      let nextPageCount = parseInt(Math.ceil(props.featuredShowCount / 20));
      setSecondStep(nextPageCount);
      props.resetLoginPhase();
    } else if (get(props, "featuredShowPhase", "") === "error") {
      props.resetLoginPhase();
    }
  }, [props, showsList]);

  const pageChange = (value) => {
    if (value === "next") {
      let pageNum = firstStep + 1;
      setFirstStep(pageNum);
      props.getFeaturedShows({ pageNumber: pageNum, pageSize: 20 });
    } else if (value === "previous") {
      // let prev = page - 2
      // setPreviousStep(prev)
      let pageNum = firstStep - 1 === 0 ? 1 : firstStep - 1;
      setFirstStep(pageNum);
      props.getFeaturedShows({ pageNumber: pageNum, pageSize: 20 });
    }
  };

  const handleChangeFeaturedShow = async (id) => {
    let test = showsList;
    const data = test?.find((t) => t?._id.toString() === id.toString());
    let newStatus = !data?.status;

    let newFeatureEpisode = [];
    let s = test?.map((t) => {
      if (t) {
        if (t?._id.toString() === id.toString()) {
          t.status = newStatus;
        }
        newFeatureEpisode.push(t);
      }
    });
    await Promise.all(s);
    setShowsList(newFeatureEpisode);
    const { value } = await props.hideFeatureEpisodes({ id: id });

    if (value?.status === true) {
      window.location.reload();
    }
  };

  const handleChangeText = (name, value) => {
    if (name === "description") {
      if (value.length <= 80) {
        selectedData[name] = value;
      }
    } else {
      selectedData[name] = value;
    }
    setSelectedData({ ...selectedData });
  };
  const bytesToSize = (bytes) => bytes / (2048 * 2048);

  const addImages = async (acceptedFiles, keys) => {
    if (acceptedFiles.length > 0) {
      setShowLoader(true);
      setImageError("");
      if (acceptedFiles.length > 1) {
        const imageError = "Maximum 1 photo";
        setImageError(imageError);
      } else {
        acceptedFiles.map(async (data, key) => {
          if (data.type.split("/")[0] === "image") {
            // if (this.bytesToSize(data.size) <= 10) {
            acceptedFiles[key].preview = URL.createObjectURL(data);
            const fileSize = (bytesToSize(acceptedFiles[key].size) * 10) / 100;
            const newFle = await imageCompression(acceptedFiles[key], {
              maxSizeMB: fileSize,
            });
            const file = new File([newFle], "image");
            let form_data = new FormData();
            form_data.append("image", newFle);
            let { value } = await props.uploadImages(form_data);
            // selectedData["image"] = value?.url;
            setImage(value?.url);
            setImageTrue(false);
            // setSelectedData(selectedData);
            setShowPreviewArr(data);
            setShowLoader(false);
            // selectedData['image'] = data
            // setSelectedData(selectedData)
          } else {
            setImageError("Please use jpeg or png format.");
            setShowLoader(false);
          }
        });
      }
    } else {
      setImageError("Please use jpeg or png format.");
      setShowLoader(false);
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };

  const deleteFeaturedShows = async (id) => {
    let { value } = await props.deleteFeaturedShows({ _id: id });
    if (get(value, "success", "")) {
      props.getFeaturedShows({ pageNumber: 1, pageSize: 20 });
      openNotificationWithIcon(
        "success",
        "Deleted featured details successfully."
      );
      setIsOpen(false);
      setDeleteOpenModal(false);
      setShowPreviewArr({});
      setSelectedData({});
    }
  };

  const addFeatured = async () => {
    if (!text) {
    } else {
      let { value } = await props.addFeaturedText({
        _id: featuredData?._id,
        text: text,
      });
      if (get(value, "success", "") === true) {
        let time = setInterval(async () => {
          openNotificationWithIcon(
            "success",
            "Update Featured text successfully."
          );
          //props.getFeaturedShows({ pageNumber: 1, pageSize: 30 });
          setOpenTextModal(false);
          const res = await props.getFeaturedText({});
          // console.log(res.value,"res.value>>>>>>>>>>>");
          if (res.value.success) {
            setFeaturedData(res.value.data);
            setText(res.value.data.text);
          }

          clearInterval(time);
        }, 1000);
      }
    }
  };

  const addFeaturedShows = async (key) => {
    if (selectedStatus === "Feature by Show") {
      selectedData["seasonId"] = null;
      selectedData["episodeId"] = null;
    }

    if (selectedStatus === "Feature by URL") {
      selectedData["seriesId"] = null;
      selectedData["seasonId"] = null;
      selectedData["episodeId"] = null;
    }
    if (selectedStatus !== "Feature by URL") {
      selectedData["feedUrl"] = "";
    }

    selectedData["type"] =
      selectedStatus === "Feature by Show"
        ? "featuredByShow"
        : selectedStatus === "Feature an Episode"
        ? "featuredByEpisodes"
        : "featuredByUrl";

    if (key === "updatestatus") {
      selectedData["status"] =
        selectedData["status"] === "active" ? "inactive" : "active";
      let { value } = await props.featuredShows(selectedData);
      if (get(value, "success", "")) {
        props.getFeaturedShows({ pageNumber: 1, pageSize: 20 });
        openNotificationWithIcon(
          "success",
          "Update featured details successfully."
        );
        setImage("");
        setIsOpen(false);
        setDeleteOpenModal(false);
        setShowPreviewArr({});
        setSelectedData({});
      }
    } else {
      let err
      if (image) {
        selectedData["image"] = image;
      }
      if (selectedStatus === "Feature by Show") {
        if (!get(selectedData, "seriesId", "") || selectedData?.seriesId === "0") {
          err = true
          set_page_state({ is_select_show_error: true })
        }
        if (!selectedData?.description || selectedData?.description?.length <= 0) {
          err = true
          set_page_state({ is_featured_description_error: true })
        }
      }

      if (selectedStatus === "Feature by URL") {
        console.log({ selectedData })
        if (!selectedData?.feedUrl || selectedData?.feedUrl?.length <= 0) {
          err = true
          set_page_state({ is_feed_url_error: true })
        }
        if (!selectedData?.description || selectedData?.description?.length <= 0) {
          console.log("work 2")
          err = true
          set_page_state({ is_featured_description_error: true })
        }
      }

      if ( seasonsData?.length > 0 && selectedStatus === "Feature an Episode") {

        
       
        if (!selectedData?.seriesId || selectedData?.seriesId === "0") {
          err = true
          set_page_state({ is_select_show_error: true })
        }
        // if (!selectedData?.episodeId || selectedData?.episodeId === "0") {
        //   console.log("work1")
        //   err = true
        //   set_page_state({ is_select_episode: true })
        // }
        if (!selectedData?.seasonId || selectedData?.seasonId === "0") {
          err = true
          set_page_state({ is_select_season_error: true })
        }
        if (!selectedData?.description || selectedData?.description?.length <= 0) {
          console.log("work 2")
          err = true
          set_page_state({ is_featured_description_error: true })
        }
      }

      if ( selectedStatus === "Feature an Episode") {

        console.log("i m inside validation2 ")

       
        if (!selectedData?.seriesId || selectedData?.seriesId === "0") {
          err = true
          set_page_state({ is_select_show_error: true })
        }
        // if (!selectedData?.episodeId || selectedData?.episodeId === "0") {
        //   console.log("work1")
        //   err = true
        //   set_page_state({ is_select_episode: true })
        // }
        // if (!selectedData?.seasonId || selectedData?.seasonId === "0") {
        //   err = true
        //   set_page_state({ is_select_season_error: true })
        // }
        if (!selectedData?.description || selectedData?.description?.length <= 0) {
          console.log("work 2")
          err = true
          set_page_state({ is_featured_description_error: true })
        }
      }




      if (!get(selectedData, "preview", "") && !get(selectedData, "image", "")) {
        err = true
        set_page_state({ is_image_error: true })
      }


      console.log(selectedData, "<<condition")

      if (!err) {
        if (get(selectedData, "_id", "")) {
          if (image !== "") {
            selectedData["image"] = image;
          }
          let { value } = await props.featuredShows(selectedData);
          if (value?.success) {
            props.getFeaturedShows({ pageNumber: 1, pageSize: 20 });
            openNotificationWithIcon(
              "success",
              "Update featured details successfully."
            );

            setImage("");
            setIsOpen(false);
            setShowPreviewArr({});
            setSelectedData({});
            set_page_state(initial_page_state)
          }
          else {
            openNotificationWithIcon(
              "error",
              "Something went wrong, Please try again!"
            );
          }
        } else {
          try {
            let { value } = await props.featuredShows(selectedData);
            if (value?.success) {
              props.getFeaturedShows({ pageNumber: 1, pageSize: 20 });
              openNotificationWithIcon(
                "success",
                "Add featured details successfully."
              );
              setImage("");
              setIsOpen(false);
              setShowPreviewArr({});
              setSelectedData({});
              set_page_state(initial_page_state)
            }
            else {
              openNotificationWithIcon(
                "error",
                "Something went wrong, Please try again!"
              );
            }
          } catch (error) {
            openNotificationWithIcon(
              "error",
              error?.message
            );
          }
        }
      }
    }
  };

  return (
    <>
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row">
            <div className="page__header--left">
              <h1 className="page__header--title">App Home Screen</h1>
            </div>
            <div className="page__header--right">
              {/*<button
                onClick={() => openIsModal()}
                type="button"
                className="btn btn__default"
              >
                <Setting className="btn__icon" /> Show Settings
              </button>*/}

              <button
                onClick={() => {
                  openModalForText();
                  setSelectedData({});
                }}
                type="button"
                className="btn btn__green"
              >
                Edit Featured Text
              </button>
              <button
                onClick={() => {
                  openModal();
                  setSelectedData({});
                  setImage("");
                }}
                type="button"
                className="btn btn__green"
              >
                <Plus className="btn__icon" /> Add Featured Show or Episode
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          <table className="table table__custom">
            <thead>
              <tr>
                <th className="table__selection--column"></th>
                <th>Featured Short Description</th>
                {/* <th>Featured Show</th> */}
                <th>Source(Show/Episode/Url)</th>
                <th className="text-center">Hide Feature(Show/Episode/Url)</th>
              </tr>
            </thead>

            <tbody>
              {showsList.length === 0 && loader ? (
                <Spin
                  size="large"
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 30, color: "#000" }}
                      spin
                    />
                  }
                  className="loader__full"
                />
              ) : (
                showsList.map((l, i) => (
                  <tr key={i}>
                    <td className="table__selection--column">
                      <button
                        className="btn"
                        // onClick={() => {
                        //   openModal();
                        //   // l["seasonId"] = l["seasonId"] ? l["seasonId"] : null;
                        //   // l["episodeId"] = null;
                        //   setSelectedData({ ...l });

                        //   setSelectedStatus(
                        //     l.type === "featuredByShow"
                        //       ? "Feature by Show"
                        //       : "Feature an Episode"
                        //   );
                        //   // if (
                        //   //   l["seasonId"] !== null &&
                        //   //   l["episodeId"] !== null
                        //   // ) {
                        //   let seasonData = showsListData.find(
                        //     (s) =>
                        //       s._id.toString() === l?.seriesId?._id?.toString()
                        //   );
                        //   setEpisodeData([]);
                        //   //setSeasonsData([]);
                        //   // setSelectedData({ ...selectedData });
                        //   if (seasonData?.isSeason === "false") {
                        //     setSeasonsData([]);
                        //     setEpisodeData([...seasonData?.episodes]);
                        //   } else {
                        //     setSeasonsData([...seasonData?.seasons]);
                        //     let episodeData = seasonData?.seasons?.find(
                        //       (s) =>
                        //         s._id.toString() === l?.seasonId?.toString()
                        //     );
                        //     setEpisodeData([...episodeData?.episodesIds]);
                        //   }
                        //   // }
                        // }}
                        onClick={() => {
                          openModal(l);
                          setSelectedData(l);
                          setImage(l?.image);
                          setImageTrue(false);
                          // selectedData["seasonId"] = null;
                          // selectedData["episodeId"] = null;
                          // setSelectedData({ ...selectedData });

                          setSelectedStatus(
                            l.type === "featuredByShow"
                              ? "Feature by Show"
                              : l.type === "featuredByEpisodes"
                              ? "Feature an Episode"
                              : "Feature by URL"
                          );

                          if (selectedData["seriesId"] !== null) {
                            if (
                              selectedData["seasonId"] === null &&
                              selectedData["episodeId"] === null
                            ) {
                              let seasonData = showsListData.find(
                                (s) =>
                                  s._id.toString() ===
                                  l?.seriesId?._id?.toString()
                              );
                              setEpisodeData([]);
                              //setSeasonsData([]);

                              // setSelectedData({ ...selectedData });
                              if (seasonData?.isSeason === "false") {
                                let episodes = seasonData?.episodes
                                setSeasonsData([]);
                                setEpisodeData(episodes);
                              } else {
                                let episodeData = seasonData?.seasons?.find(
                                  (s) =>
                                    s._id.toString() === l?.seasonId?.toString()
                                );
                                // setSeasonsData([]);

                                
                                let episodesIds = episodeData?.episodesIds
                                let seasons = seasonData?.seasons
                                setEpisodeData(episodesIds);
                                setSeasonsData(seasons);
                              }
                            }
                            // setSelectedData(l);
                          }
                        }}
                      >
                        <Eye />
                      </button>
                    </td>
                    <td>
                      <div className="short-desc">
                        {l.description
                          ?.replaceAll("<p>", "")
                          .replaceAll("</p>", "")
                          .replaceAll("<br>", "")}
                      </div>
                    </td>
                    {/* {l?.seriesId?.title ? (
                      <td>{l?.seriesId?.title}</td>
                    ) : (
                      <td>-</td>
                    )} */}

                    {l?.episodeId === null &&
                    l?.seasonId === null &&
                    l?.feedUrl === "" ? (
                      <td>{l?.seriesId?.title}</td>
                    ) : l?.episodeId === null && l?.seasonId === null ? (
                      <td>{l?.feedUrl}</td>
                    ) : (
                      <td>{l?.episodeTitle}</td>
                    )}
                    <td className="hide-app-icon-td checkbox-two-items text-center">
                      <input
                        type="checkbox"
                        onChange={() => handleChangeFeaturedShow(l._id)}
                        id="subscribe"
                        name="subscribe"
                        checked={!l?.status === false ? false : true}
                      />
                    </td>

                    <td className="table__cell--last"
                      onClick={() => {
                        setSelectedData(l);
                        setImage(l?.image);
                      }}>
                      {/* <ActionMoreComponent
                        setOpenModalData={setSelectedData}
                        keyV="featured-app"
                        setModalIsOpen={openModal}
                        data={l}
                        openModalHide={openModalDelete}
                      /> */}
                      <ActionMoreComponent
                        selectedData={selectedData}
                        image={l?.image}
                        setImage={setImage}
                        setSelectedStatus={setSelectedStatus}
                        setSeasonsData={setSeasonsData}
                        setEpisodeData={setEpisodeData}
                        setOpenModalData={setSelectedData}
                        showsListData={showsListData}
                        setSelectedData={setSelectedData}
                        keyV="featured-app"
                        setModalIsOpen={openModal}
                        data={l}
                        setModalIsOpen={() => openModal(l)}
                        openModalHide={() => openModalDelete(l)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {showsList.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(showsList)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {firstStep - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {secondStep - firstStep > 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={deleteOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Are you sure?"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        {/* <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title ">Remove</h5>
          </div>
          <div className="react-modal-body">
            <p className="react-modal-body-text">
              Are you sure do you want to Remove this ?
            </p>
          </div>
          <div className="react-modal-footer">
            <button onClick={closeModal} className="btn btn__default">
              Cancel
            </button>
            <button
              onClick={() => deleteFeaturedShows(selectedData._id)}
              className="btn btn__purple ms-2"
            >
              Yes
            </button>
          </div>
        </div>*/}
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title react-modal-title-md">Delete</h5>
            <p className="react-modal-subtitle">
              Are you sure you want to delete this? This cannot be undone.
            </p>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => closeModal()}
            >
              <Close />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="text-right">
              <button
                onClick={() => deleteFeaturedShows(selectedData._id)}
                className="btn btn-danger btn-lg w-150"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      {/* <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        //contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
       

        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Featured Show</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Select Show </label>

              <select
                onChange={(e) => {
                  selectedData["seriesId"] = e.target.value;
                  setSelectedData({ ...selectedData });
                }}
                value={
                  get(selectedData, "seriesId._id", "")
                    ? get(selectedData, "seriesId._id", "")
                    : get(selectedData, "seriesId", "")
                }
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                {showsListData.length > 0 &&
                  showsListData?.map((s, i) => {
                    return (
                      <option value={s._id} key={i}>
                        {s.title}
                      </option>
                    );
                  })}
              </select>

              {!get(selectedData, "seriesId", "") && (
                <div className="invalid-feedback">Please select series Id</div>
              )}
            </div>

            <div className="form-group">
              <label className="label-primary">
                Featured Short Description{" "}
              </label>
              <textarea
                type="text"
                name="description"
                onChange={(e) =>
                  handleChangeText(e.target.name, e.target.value)
                }
                value={get(selectedData, "description", "")}
                className="form-control material-textfield-input"
                rows={3}
                required
              ></textarea>
              <div className="textarea-text-length">
                {get(selectedData, "description", "").length}/80
              </div>
              {!get(selectedData, "description", "") && (
                <div className="invalid-feedback">Please enter description</div>
              )}
            </div>
            <div className="form-group">
              <label className="label-primary">
                Featured Photo <i>(recommended dimensions)</i>
              </label>
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) =>
                  addImages(acceptedFiles, "showImage")
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {showLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {!get(showPreviewArr, "preview", "") &&
                !get(selectedData, "image", "") && (
                  <div className="invalid-feedback">
                    Please upload show image.
                  </div>
                )}
              {(get(selectedData, "image", "") || !isEmpty(showPreviewArr)) && (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(showPreviewArr)
                          //   ? showPreviewArr.preview
                          get(selectedData, "image", "")
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setShowPreviewArr({});
                            selectedData["image"] = "";
                            setSelectedData(selectedData);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="react-modal-footer">
            <button
              onClick={() => {
                closeModal();
                setShowPreviewArr({});
              }}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              onClick={() => addFeaturedShows("notupdatestatus")}
              type="submit"
              className="btn btn__purple"
            >
              Save
            </button>
          </div>
        </div>
      </ReactModal> */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        //contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-sm modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">
              + Add Featured Show or Episode
            </h5>
          </div>
          <div className="react-modal-body">
            <div className="custom__radio__group">
              {activeStatus.map((d, i) => {
                return (
                  <>
                    <input
                      className="custom__radio__group--button"
                      id={d.key}
                      type="radio"
                      checked={selectedStatus === d.key}
                      onClick={() => {
                        set_page_state(initial_page_state)
                        setSelectedData["status"] = d.key;
                        onSelectedStatus("type", d.key);
                      }}
                    />
                    <label
                      className="custom__radio__group--label lable-spacing"
                      htmlFor={d.key}
                    >
                      {d.value}
                    </label>
                  </>
                );
              })}
            </div>
            {selectedStatus !== "Feature by URL" && (
              <div className="form-group">
                <label className="label-primary">Select Show</label>

                <select
                  onChange={(e) => {
                    set_page_state({ is_select_show_error: false })
                    selectedData["seriesId"] = e.target.value;
                    setSelectedData({ ...selectedData, seasonId: "" });
                    let seasonData = showsListData.find(
                      (s) => s._id.toString() === e.target.value.toString()
                    );
                    setEpisodeData([]);
                    //setSeasonsData([]);
                    selectedData["seasonId"] = null;
                    selectedData["episodeId"] = null;
                    // setSelectedData({ ...selectedData });
                    if (seasonData?.isSeason === "false") {
                      let episodes = seasonData?.episodes
                      setSeasonsData([]);
                      setEpisodeData(episodes);
                    } else {
                
                      let seasons = seasonData?.seasons
                      setSeasonsData(seasons)
                    }
                    // setSelectedData({ ...selectedData });
                  }}
                  value={
                    get(selectedData, "seriesId._id", "")
                      ? get(selectedData, "seriesId._id", "")
                      : get(selectedData, "seriesId", "")
                  }
                  className="form-control custom-select material-textfield-input"
                  required
                >
                  <option value="0">Select</option>
                  {showsListData.length > 0 &&
                    showsListData?.map((s, i) => {
                      const titleToShow = s.isMegaphone === true ? "megaphone" : "Art19";
                      return (
                        <option value={s._id} key={i} className="optionWithSpacing">
                              {s.title + " " + " - " + " " + titleToShow}
                        </option>
                      );
                    })}
                </select>

                {page_state?.is_select_show_error &&
                  <div className="invalid-feedback">
                    Please select show
                  </div>
                }
              </div>
            )}

            {selectedStatus === "Feature by URL" && (
              <div className="form-group">
                <label className="label-primary">URL</label>

                <input
                  name="feedUrl"
                  value={get(selectedData, "feedUrl", "")}
                  onChange={(e) => {
                    set_page_state({ is_feed_url_error: false })
                    handleTextChange(e.target.name, e.target.value)
                  }
                  }
                  className="form-control custom-select material-textfield-input"
                  required
                />
                {
                  page_state?.is_feed_url_error &&
                  <div className="invalid-feedback">
                    Please enter featuredURL
                  </div>
                }
              </div>
            )}
            {selectedStatus === "Feature an Episode" &&
              seasonsData?.length > 0 && (
                <div className="form-group">
                  <label className="label-primary">Select Season</label>
                  <select
                    onChange={(e) => {
                      set_page_state({ is_select_season_error: false })
                      selectedData["seasonId"] = e.target.value;
                      selectedData["episodeId"] = null;
                      setSelectedData({ ...selectedData });

                      let episodeData = seasonsData.find(
                        (s) => s._id.toString() === e.target.value.toString()
                      );
                      let episodesIds = episodeData?.episodesIds
                      setEpisodeData(episodesIds)
                    }}
                    value={get(selectedData, "seasonId", "")}
                    className="form-control custom-select material-textfield-input"
                    required
                  >
                    <option value="0">Select</option>
                    {seasonsData?.length > 0 &&
                      seasonsData?.map((s, i) => {
                        if (s?.episodesIds?.length !== 0) {
                          return (
                            <option key={i} value={s._id}>
                              {s.title}
                            </option>
                          );
                        }
                      })}
                  </select>
                  {page_state?.is_select_season_error &&
                    <div className="invalid-feedback">
                      Please select season Id
                    </div>
                  }
                </div>
              )}

            {selectedStatus === "Feature an Episode" && (
              <div className="form-group">
                <label className="label-primary">Select Episode</label>
                <select
                  onChange={(e) => {
                    set_page_state({ is_select_episode: false })
                    selectedData["episodeId"] = e.target.value;
                    setSelectedData({ ...selectedData });
                  }}
                  value={get(selectedData, "episodeId", "")}
                  className="form-control custom-select material-textfield-input"
                  required
                >
                  <option value="0">Select</option>
                  {episodeData?.length > 0 &&
                    episodeData?.map((s, i) => {
                      return (
                        <option key={i} value={s._id}>
                          {s.title}
                        </option>
                      );
                    })}
                </select>
                {page_state?.is_select_episode && (
                  <div className="invalid-feedback">
                    Please select episode Id
                  </div>
                )}
              </div>
            )}
            <div className="form-group">
              <label className="label-primary">
                Featured Short Description{" "}
              </label>
              <textarea
                type="text"
                name="description"
                onChange={(e) => {
                  set_page_state({ is_featured_description_error: false })
                  handleChangeText(e.target.name, e.target.value)
                }}
                value={get(selectedData, "description", "")}
                className="form-control material-textfield-input"
                rows={3}
                required
              ></textarea>
              <div className="textarea-text-length">
                {get(selectedData, "description", "").length}/80
              </div>
              {page_state?.is_featured_description_error &&
                <div className="invalid-feedback">Please enter description</div>
              }
            </div>
            <div className="form-group">
              <label className="label-primary">
                Featured Photo <i>(recommended dimensions)</i>
              </label>
              <Dropzone
                accept="image/*"
                multiple={false}
                // disabled={this.state.previewArr.length >= 50}
                onDrop={(acceptedFiles) => {
                  set_page_state({ is_image_error: false })
                  addImages(acceptedFiles, "showImage")
                }
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div className="dropzone__area" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <button type="button" className="btn btn__green">
                        {showLoader ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <Plus className="btn__icon" /> Upload Photo
                          </>
                        )}
                      </button>
                    </div>
                  </section>
                )}
              </Dropzone>
              {imageError !== "" && (
                <>
                  <div className="invalid-feedback">{imageError}</div>
                </>
              )}
              {/* {!get(showPreviewArr, "preview", "") &&
                !get(selectedData, "image", "") && (
                imageError === "Please use jpeg or png format." ? 
                <></> : 
                  <div className="invalid-feedback">
                    Please upload show image.
                  </div>
                )} */}
              {image !== "" ? (
                <div className="dropzone__grid">
                  <div className="dropzone__grid--item">
                    <div className="dropzone__grid--preview">
                      <img
                        className="preview__thumb"
                        src={
                          // !isEmpty(showPreviewArr)
                          //   ? showPreviewArr.preview
                          image
                          // setData(get(selectedData, "image", ""))
                        }
                        alt=""
                      />
                      <div className="dropzone__grid--remove">
                        <button
                          onClick={() => {
                            setImageTrue(true);
                            setImageError("");
                            setImage("");
                            setShowPreviewArr({});
                            // selectedData["image"] = "";
                            // setSelectedData(selectedData);
                          }}
                          className="btn btn__remove"
                        >
                          <Remove />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                page_state?.is_image_error && (
                  <div className="invalid-feedback">
                    Please upload show image.
                  </div>
                )
              )}
            </div>
          </div>

          <div className="react-modal-footer">
            <button
              onClick={() => {
                setImageTrue(true);
                closeModal();
                setImage("");
                setShowPreviewArr({});
                set_page_state(initial_page_state)
              }}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button
              // disabled={imageTrue}
              onClick={() => addFeaturedShows("notupdatestatus")}
              type="submit"
              className="btn btn__purple"
            >
              Save
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={opentextModal}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Edit Featured Text</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">Featured Text</label>

              <input
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="form-control custom-select material-textfield-input"
                required
              />
              {!text && (
                <div className="invalid-feedback">
                  Please enter featured text
                </div>
              )}
              <div className="react-modal-footer">
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="btn btn__default me-2"
                >
                  Cancel
                </button>
                {/* <button type="submit" className="btn btn__purple">
                  Save
                </button> */}
                <button
                  onClick={() => addFeatured()}
                  type="submit"
                  className="btn btn__purple"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalIsShowOpen}
        onRequestClose={closeModal}
        contentLabel=""
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Show setting</h5>
          </div>
          <div className="react-modal-body">
            <div className="form-group">
              <label className="label-primary">
                Home: Set Featured Podcast Season
              </label>

              <select
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                <option value="0">S1</option>
                <option value="0">S2</option>
              </select>
              <div className="invalid-feedback">dsvsdvds</div>
            </div>
            <div className="form-group">
              <label className="label-primary">
                Home: Set Featured Podcast Episode
              </label>
              <select
                className="form-control custom-select material-textfield-input"
                required
              >
                <option value="0">Select</option>
                <option value="0">S1</option>
                <option value="0">S2</option>
              </select>
              <div className="invalid-feedback">dsvsdvds</div>
            </div>
            <div className="form-group">
              <label className="label-primary">
                Featured Photo <i>(recommended dimensions)</i>
              </label>
              imagepicker component
            </div>
          </div>
          <div className="react-modal-footer">
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn__default me-2"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn__purple">
              Save
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
