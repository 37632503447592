import { connect } from "react-redux";
import NewShowFromArt19Component from "./AddShow/component";
import {
getList,
getMegaphoneList,
syncSeriesRecords,
syncMegaphoneSeriesRecords,
  
} from "../../store/dashboard/duck";

const UploadSeasonOrEpisodeContainer = connect(
  // Map state to props
  (state) => ({
    updateEpisodeDetailsPhase: state.dashboard.updateEpisodeDetailsPhase,
    getShowDetailsByIdPhase: state.dashboard.getShowDetailsByIdPhase,
    getShowDetailsByIdData: state.dashboard.getShowDetailsByIdData,
    updateShowDetailsPhase: state.dashboard.updateShowDetailsPhase,
    updateShowDetailsMessage: state.dashboard.updateShowDetailsMessage,
    categoryCount: state.dashboard.categoryCount,
    categoryData: state.dashboard.categoryData,
    categoryPhase: state.dashboard.categoryPhase,
    updateSeasonImageData: state.dashboard.updateSeasonImageData,
    updateSeasonImagePhase: state.dashboard.updateSeasonImagePhase,
    updateEpisodeSeasonOrderIndexData: state.dashboard.updateEpisodeSeasonOrderIndexData,
    updateEpisodeSeasonOrderIndexPhase: state.dashboard.updateEpisodeSeasonOrderIndexPhase,
    sendGeneralNotificationsData: state.dashboard.sendGeneralNotificationsData,
    sendGeneralNotificationsPhase: state.dashboard.sendGeneralNotificationsPhase,
    getListData: state.dashboard.getListData,
    getListPhase: state.dashboard.getListPhase,
    

  }),
  // Map actions to dispatch and props
  {
    getList,
    syncSeriesRecords,
    getMegaphoneList,
    syncMegaphoneSeriesRecords
  }
)(NewShowFromArt19Component);

export default UploadSeasonOrEpisodeContainer;